import PropTypes from 'prop-types';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Link } from "react-router-dom";

const AssetsTable = ({ assets, ...props }) => {


    return (
        <div>
            <Table responsive bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Edit/View</th>
                    </tr>
                    
                </thead>

                <tbody>
                    {assets.map(asset => {
                        return (
                            <tr key={asset.id}>
                                <td>{asset.name}</td>
                                <td>{asset.assetType}</td>
                                <td><Link to={{ pathname: `/asset/${asset.id}`}}>View</Link></td>
                            </tr>
                       )
                    })}
                </tbody>
            </Table>
        </div>
    );
}


AssetsTable.propTypes = {
    assets: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            assetType: PropTypes.string
        })
    ),
    props: PropTypes.object,
}

export default AssetsTable;