import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../../providers/realm/realmApp";
import { toggleBoolean } from "../../utils";
import { useErrorAlert } from "../../hooks/useErrorAlert";
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';
import SignOnRequirements from '../../components/job/SignOnRequirements';
import '../../views/index.css';


function SiteRestrictions() {
    return (
        <Form.Group className="mb-3">
            <p>Site Restricitons: </p>
            <div class="overflow-auto" style={{ height: "150px", padding: "10px", border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
                <Form.Check
                    label="No welding"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="No power tools "
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="No grinding"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Low dust"
                    name="group1"
                    type="checkbox"
                />

                <Form.Check
                    label="No naked flame"
                    name="group1"
                    type="checkbox"
                />
            </div>
        </Form.Group>
    );
}

function SiteRequiredQualifications() {
    return (
        <Form.Group className="mb-3">
            <p>Site Required Qualifications: </p>
            <div class="overflow-auto" style={{ height: "150px", padding: "10px", border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
                <Form.Check
                    label="White Card"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Manual Drivers Licence"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Forklift Licence"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Welding ticket"
                    name="group1"
                    type="checkbox"
                />

                <Form.Check
                    label="Working at heights ticket"
                    name="group1"
                    type="checkbox"
                />
            </div>
        </Form.Group>
    );
}

function AssignedWorkers() {
    return (
        <Form.Group className="mb-3">
            <p>Assigned Workers: </p>
            <div class="overflow-auto" style={{ height: "150px", padding: "10px", border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
                <Form.Check
                    label="Joe Blogs"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="John Smith"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="John Doe"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Steve Steve"
                    name="group1"
                    type="checkbox"
                />
            </div>
        </Form.Group>
    );
}

function EditJob(props) {

    document.title = title('Edit Job');

    const handleSubmit = (event) => {
        event.preventDefault();

        // var claimedOwner = event.target[0].value;

        // claimItem({ variables: { id: itemId, claimedOwner: claimedOwner, status: "claimed" } });

        // window.location.reload();

    };

    return (

        <div>
            <Topbar title={"Edit Job"} />
            <div className='page-container'>
                <Col sm={6}>
                    <Form onSubmit={handleSubmit}>

                        <Form.Group className="mb-3">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control id="inlineFormInputName" value="New Vat" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Job Code: </Form.Label>
                            <Form.Control id="inlineFormInputName" value={"kew-job-p-nv-21-12-c".toUpperCase()} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Client:</Form.Label>
                            <Form.Select defaultValue="Don's Winery" >
                                <option value="Don's Winery">Don's Winery</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Start Date:</Form.Label>
                            <Form.Control id="inlineFormInputName" value="2021/11/23" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Address:</Form.Label>
                            <Form.Control id="inlineFormInputName" value="123 Wine Dr, Kyneton" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Project Status:</Form.Label>
                            <Form.Select defaultValue='In Progress' >
                                <option value="In Progress">In Progress</option>
                                <option value="Complete">Complete</option>
                                <option value="Not Started">Not Started</option>
                            </Form.Select>
                        </Form.Group>

                        <SignOnRequirements />

                        <SiteRestrictions />

                        <SiteRequiredQualifications />

                        <AssignedWorkers />

                        <Button variant="primary">
                            Save
                        </Button>
                    </Form>
                </Col>

            </div>
        </div>
    );
}


export default EditJob;