
import { useAllJobs } from "./queries/useAllJobsQuery";


const useJobs = () => {
    const { jobs, loading } = useAllJobs();
    return {
        jobs,
        loading,
    };
};

export default useJobs;