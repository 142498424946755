import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../../providers/realm/realmApp";
import { toggleBoolean } from "../../utils";
import { useErrorAlert } from "../../hooks/useErrorAlert";
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';
import validators from '../../helpers/validators';
import '../../views/index.css';
function CreateUser(props) {

    document.title = title('Create User');

    const { realmApp, currentUser } = useRealmApp();

    // Authentication errors
    const noErrors = {
        email: null,
        password: null,
        other: null,
    };

    const defaultForm = {
        firstName: 'Test',
        lastName: 'Testing',
        email: 'test2@test.com',
        password: 'test1234',
        phone: '0412123123',
        userType: 'Employee'
    }

    const [error, setError] = React.useState(noErrors);
    const [showRelationship, setShowRelationship] = useState(false);
    const [form, setForm] = useState(defaultForm);
    const [formError, setFormError] = useState({});

    const clearErrors = () => {
        setError(noErrors);
        setFormError({});
    }

    const NonAuthErrorAlert = useErrorAlert({
        error: error.other,
        clearError: () => {
            setError((prevError) => ({ ...prevError, other: null }));
        },
    });


    const onSelectChange = (event) => {
        if (event.target.value === 'Client') {
            setShowRelationship(true);
        }
        else {
            setShowRelationship(false);
        }

        handleFormChange('userType', event);
    }



    const handleFormChange = (name, event) => {
        setForm({
            ...form,
            [name]: event.target.value
        })
    }

    const findFormErrors = () => {
        const { firstName, lastName, email, password, phone, userType } = form;

        const newErrors = {};

        //firstName errors, safe destructuring cuz it returns nothing when value is valid
        const { isValid: fNameValid, errorMessage: fNameError } = validators.validateFirstname(firstName);
        if (!fNameValid) newErrors.firstName = fNameError;

        //lastName errors
        const { isValid: lNameValid, errorMessage: lNameError } = validators.validateLastName(lastName);
        if (!lNameValid) newErrors.lastName = lNameError;

        //email errors
        const { isValid: emailValid, errorMessage: emailError } = validators.validateEmail(email);
        if (!emailValid) newErrors.email = emailError;

        //password erors
        const { isValid: pwdValid, errorMessage: pwdError } = validators.validatePassword(password);
        if (!pwdValid) newErrors.password = pwdError;

        //phone errors
        const { isValid: pValid, errorMessage: phoneError } = validators.validatePhone(phone);
        if (!pValid) newErrors.phone = phoneError;

        //userType errors
        const { isValid: typeValid, errorMessage: typeError } = validators.validateUserType(userType);
        if (!typeValid) newErrors.userType = typeError;

        return newErrors;
    }

    const onFormSubmit = async (e) => {
        const errors = findFormErrors();

        if (Object.keys(errors).length > 0) {
            setFormError(errors);
            return;
        }

        clearErrors();

        try {

            const { email, password } = form;

            const meta = { "test": "testing" };

            // var result = await realmApp.emailPasswordAuth.registerUser(email, password);

            var result = await currentUser.functions.registerNewUser({ email, password });

        } catch (err) {
            handleAuthenticationError(err, setError);
        }
    };

    return (
        <div>
            <Topbar title={"Create User"} />
            <div className='page-container'>
                <Col sm={6}>
                    <Form className="auth-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            // const formData = new FormData(e.target);
                            // const { email, password } = Object.fromEntries(formData.entries());
                            onFormSubmit();
                        }}>
                        <NonAuthErrorAlert />

                        <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type='text'
                                id="inlineFormInputName"
                                placeholder="Jane"
                                name="firstName"
                                value={form.firstName}
                                onChange={e => handleFormChange('firstName', e)}
                                isInvalid={!!formError.firstName}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.firstName}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Last Name </Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="Doe"
                                name='lastName'
                                value={form.lastName}
                                onChange={e => handleFormChange('lastName', e)}
                                isInvalid={!!formError.lastName}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.lastName}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formLoginEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                //type="email"
                                placeholder="Enter email"
                                name="email"
                                value={form.email}
                                onChange={e => handleFormChange('email', e)}
                                isInvalid={!!formError.email}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.email}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formLoginPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={form.password}
                                onChange={e => handleFormChange('password', e)}
                                isInvalid={!!formError.password}
                            />
                            <Form.Control.Feedback type='invalid' >
                                {formError.password}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number: </Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="0401 001 001"
                                name="phone"
                                value={form.phone}
                                onChange={e => handleFormChange('phone', e)}
                                isInvalid={!!formError.phone}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.phone}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>User Type:</Form.Label>
                            <Form.Select onChange={(e) => { onSelectChange(e); handleFormChange('userType', e) }} isInvalid={!!formError.userType} defaultValue='' >
                                <option value="" disabled>Select Type</option>
                                <option value="administrator">Administrator</option>
                                <option value="employee">Employee</option>
                                <option value="Client">Client</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                                {formError.userType}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {showRelationship && (
                            <Form.Group className="mb-3">
                                <Form.Label>Client Relationship</Form.Label>
                                <Form.Select defaultValue='' >
                                    <option value="" disabled>Select Type</option>
                                    <option value="Aministrator">Administrator</option>
                                    <option value="Employee">Employee</option>
                                    <option value="Client">Client</option>
                                </Form.Select>
                            </Form.Group>
                        )}

                        <Button type="submit" variant="primary">
                            Create User
                        </Button>
                    </Form>
                </Col>

            </div>
        </div>
    );
}

function handleAuthenticationError(err, setError) {
    const handleUnknownError = () => {
        setError((prevError) => ({
            ...prevError,
            other: "Something went wrong. Try again in a little bit.",
        }));
        console.warn(
            "Something went wrong with a Realm login or signup request. See the following error for details."
        );
        console.error(err);
    };
    if (err instanceof Realm.MongoDBRealmError) {
        const { error, statusCode } = err;
        const errorType = error || statusCode;
        switch (errorType) {
            case "invalid username":
                setError((prevError) => ({
                    ...prevError,
                    email: "Invalid email address.",
                }));
                break;
            case "invalid username/password":
            case "invalid password":
            case 401:
                setError((prevError) => ({
                    ...prevError,
                    password: "Incorrect password.",
                }));
                break;
            case "name already in use":
            case 409:
                setError((prevError) => ({
                    ...prevError,
                    email: "Email is already registered.",
                }));
                break;
            case "password must be between 6 and 128 characters":
            case 400:
                setError((prevError) => ({
                    ...prevError,
                    password: "Password must be between 6 and 128 characters.",
                }));
                break;
            default:
                handleUnknownError();
                break;
        }
    } else {
        handleUnknownError();
    }
}


export default CreateUser;