import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GetUserQualificationByUserId = gql`
  query GetUserQualificationsByuserId($user : UserQueryInput) {
    userQualifications(query: { user_id: $user }) {
      _id
      name
      number
      description
      date_valid
      date_expires
    }
  }
`;

export function useGetUserQualificationByUserId(id) {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    GetUserQualificationByUserId,
    {
      variables: {
        user: {
          "_id": id
        },
      },
    }
  );

  const userQualifications = data?.userQualifications ?? [];
  return {
    userQualifications,
    loading,
  };
}
