import React, { useMemo, useState } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import "../../views/index.css";
import "./map.css";

export default function Map() {

  const map = useMemo(
    () => (
      <GoogleMap
        zoom={7}
        center={{
          lat: -34.206841,
          lng: 142.13649,
        }}
        clickableIcons={false}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}>
        <Markers />
      </GoogleMap>
    ),
    []
  );

  return <div className="map-parent-container">{map}</div>;
}

const markerData = [
  {
    title: "TWA HQ",
    position: { lat: -34.2404253776474, lng: 142.1632495410299 },
    picture: "/placeholder-pic.png"
  },
  { title: "Damien", position: { lat: -34.206841, lng: 142.13649 }, picture: "/placeholder-pic.png", state: 'offline' },
  { title: "Dee", position: { lat: -37.861, lng: 144.885 }, picture: "/dee.png", state: 'offline' },
  { title: "Jude", position: { lat: -37.9331, lng: 145.4369 }, picture: "/placeholder-pic.png", state: 'offline' },
  {
    title: "Dominic",
    position: { lat: -37.95504959097113, lng: 145.0411997460276 },
    picture: "/example-profile-image.png",
    state: 'online'
  },
];

const CustomMarker = (markerProps) => {
  const roundedLayer = {
    display: "flex",
    position: "relative",
    width: "50px",
    height: "50px",
    overflow: "hidden",
    borderRadius: "50%",
    margin: "auto",
  };

  const roundedLayerImage = {
    width: "auto",
    height: "100%",
    margin: "auto",
  };

  const styles = {
    active: {
      color: 'green'
    },
    inactive: {
      color: 'red'
    }
  }
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  return (
    <Marker
      key={markerProps.title}
      {...markerProps}
      onClick={() => setShowInfoWindow((prevState) => !prevState)}>
      {showInfoWindow && (
        <InfoWindow position={markerProps.position} zIndex={1000}>
          <div style={{ overflow: 'hidden' }}>
            <div className="profile-image stage" style={{ display: "flex" }}>
              <div style={roundedLayer} className="box bounce">
                <img
                  src={markerProps.picture}
                  alt=""
                  style={roundedLayerImage}
                />
              </div>
            </div>
            <h3>{markerProps.title}</h3>
            <span style={markerProps.state === 'online' ? styles.active : styles.inactive}>{markerProps.state}</span>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

const Markers = () => <>{markerData.map(CustomMarker)}</>;
