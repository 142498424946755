

export const dummyAssets = [
    {
        id: '001',
        assetType: 'Vehicle',
        name: 'Ute 1',
        make: 'Toyota',
        model: 'Hilux',
        year: '1997',
        rego: 'ABC-123',
        odometer: '315000',
        photoUrl: '/example-profile-image.png',
        serviceSchedule: [
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '340000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '350000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '360000'
            }
        ],
        
    },
   {
        id: '002',
        assetType: 'Vehicle',
        name: 'Ute 2',
        make: 'Honda',
        model: 'Hilux',
        year: '1997',
        rego: 'ABC-123',
        odometer: '315000',
        photoUrl: '/example-profile-image.png',
        serviceSchedule: [
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '340000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '350000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '360000'
            }
        ],
        
    },
   {
        id: '003',
        assetType: 'Vehicle',
        name: 'Ute 3',
        make: 'Toyota',
        model: 'Hilux',
        year: '1997',
        rego: 'ABC-123',
        odometer: '315000',
        photoUrl: '/example-profile-image.png',
        serviceSchedule: [
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '340000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '350000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '360000'
            }
        ],
        
    },
   {
        id: '004',
        assetType: 'Vehicle',
        name: 'Ute 4',
        make: 'Toyota',
        model: 'Hilux',
        year: '1997',
        rego: 'ABC-123',
        odometer: '315000',
        photoUrl: '/example-profile-image.png',
        serviceSchedule: [
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '340000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '350000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '360000'
            }
        ],
        
    },
   {
        id: '005',
        assetType: 'Vehicle',
        name: 'Ute 5',
        make: 'Toyota',
        model: 'Hilux',
        year: '1997',
        rego: 'ABC-123',
        odometer: '315000',
        photoUrl: '/example-profile-image.png',
        serviceSchedule: [
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '340000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '350000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '360000'
            }
        ],
        
    },
   {
        id: '006',
        assetType: 'Vehicle',
        name: 'Ute 6',
        make: 'Toyota',
        model: 'Hilux',
        year: '1997',
        rego: 'ABC-123',
        odometer: '315000',
        photoUrl: '/example-profile-image.png',
        serviceSchedule: [
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '340000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '350000'
            },
            {
                name: 'Service',
                description: 'Oil Change',
                dateDue: '20210601',
                avgKm: '360000'
            }
        ],
        
    },
]