import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';
import validators from '../../helpers/validators';
import '../../views/index.css';
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";

const CreateJobQuery = gql`
    mutation CreateJob($job: JobInsertInput!){
        insertOneJob(data: $job){
            _id
        }
    }
`;

function AssignedForms() {
    return (
        <Form.Group className="mb-3">
            <p>Assigned Forms: </p>

            <div class="overflow-auto" style={{ height: "150px", padding: "10px", border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
                <Form.Check
                    label="HR Form"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Policy Form"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Worksite Safety Form"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="OH&S Form"
                    name="group1"
                    type="checkbox"
                />
            </div>

        </Form.Group>
    );
}

function SignOnRequirements() {
    return (
        <Form.Group className="mb-3">
            <p>Sign On Requirements: </p>
            <div class="overflow-auto" style={{ height: "150px", padding: "10px", border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
                <Form.Check
                    label="Breath Test"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Medication"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Packing List"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Tool Box Meeting"
                    name="group1"
                    type="checkbox"
                />
            </div>
        </Form.Group>
    );
}

function SiteRestrictions() {
    return (
        <Form.Group className="mb-3">
            <p>Site Restricitons: </p>
            <div class="overflow-auto" style={{ height: "150px", padding: "10px", border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
                <Form.Check
                    label="No welding"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="No power tools "
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="No grinding"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Low dust"
                    name="group1"
                    type="checkbox"
                />

                <Form.Check
                    label="No naked flame"
                    name="group1"
                    type="checkbox"
                />
            </div>
        </Form.Group>
    );
}

function SiteRequiredQualifications() {
    return (
        <Form.Group className="mb-3">
            <p>Site Required Qualifications: </p>
            <div class="overflow-auto" style={{ height: "150px", padding: "10px", border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
                <Form.Check
                    label="White Card"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Manual Drivers Licence"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Forklift Licence"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Welding ticket"
                    name="group1"
                    type="checkbox"
                />

                <Form.Check
                    label="Working at heights ticket"
                    name="group1"
                    type="checkbox"
                />
            </div>
        </Form.Group>
    );
}

function AssignedWorkers() {
    return (
        <Form.Group className="mb-3">
            <p>Assigned Workers: </p>
            <div class="overflow-auto" style={{ height: "150px", padding: "10px", border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
                <Form.Check
                    label="Joe Blogs"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="John Smith"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="John Doe"
                    name="group1"
                    type="checkbox"
                />
                <Form.Check
                    label="Steve Steve"
                    name="group1"
                    type="checkbox"
                />
            </div>
        </Form.Group>
    );
}

function CreateJob(props) {

    document.title = title('Create Job');

    const defaultForm = {
        name: 'New Vat',
        jobCode: 'XXXX XXXX XXXX',
        client: 'Don\'s Winery',
        startDate: '2021/12/12',
        address: '123 Wine Dr',
        city: 'Kyneton',
        state: 'Victoria',
        postcode: '3444',
        country: 'Australia',
        status: 'In progress'
    }

    const [form, setForm] = useState(defaultForm);
    const [formError, setFormError] = useState({});

    const [createJob , { data, loading, error, startPolling, stopPolling }] = useMutation( CreateJobQuery );


    const clearErrors = () => {
        setFormError({});
    }


    const handleFormChange = (name, event) => {
        setForm({
            ...form,
            [name]: event.target.value
        })
    }

    const findFormErrors = () => {
        const { name, jobCode, client, startDate, address, city, state, postcode, country, status } = form;

        console.log(name, jobCode, client, startDate, address, city, state, postcode, country, status)

        const newErrors = {};

        //firstName errors, safe destructuring cuz it returns nothing when value is valid
        const { isValid: nameValid, errorMessage: nameError } = validators.validateGenericString(name);
        if (!nameValid) newErrors.name = nameError;

        //lastName errors
        const { isValid: jobCodeValid, errorMessage: jobCodeError } = validators.validateGenericString(jobCode);
        if (!jobCodeValid) newErrors.jobCode = jobCodeError;

        //client errors
        const { isValid: clientValid, errorMessage: clientError } = validators.validateGenericString(client);
        if (!clientValid) newErrors.client = clientError;

        //password erors
        const { isValid: startDateValid, errorMessage: startDateError } = validators.validateGenericString(startDate);
        if (!startDateValid) newErrors.startDate = startDateError;

        //address errors
        const { isValid: addressValid, errorMessage: addressError } = validators.validateGenericString(address);
        if (!addressValid) newErrors.address = addressError;

        //phone errors
        const { isValid: cityValid, errorMessage: cityError } = validators.validateGenericString(city);
        if (!cityValid) newErrors.city = cityError;

        //state errors
        const { isValid: stateValid, errorMessage: stateError } = validators.validateGenericString(state);
        if (!stateValid) newErrors.state = stateError;

        //postcode errors
        const { isValid: postcodeValid, errorMessage: postcodeError } = validators.validateGenericString(postcode);
        if (!postcodeValid) newErrors.postcode = postcodeError;

        //country errors
        const { isValid: countryValid, errorMessage: countryError } = validators.validateGenericString(country);
        if (!countryValid) newErrors.country = countryError;

        //project status errors
        const { isValid: statusValid, errorMessage: statusError } = validators.validateGenericString(status);
        if (!statusValid) newErrors.status = statusError;

        return newErrors;

    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const errors = findFormErrors();

        if (errors && Object.keys(errors).length > 0) {
            setFormError(errors);
            return;
        }

        clearErrors();

        const { name, jobCode, client, startDate, address, city, state, postcode, country, status } = form;

        const newJob = {
            name: name,
            job_code: jobCode,
            client_id: "",
            start_date: startDate,
            address: address,
            city: city,
            state: state,
            postcode: postcode,
            country: country,
            status: status
        }

        const { data, loading } = createJob( { variables: { job: newJob } })

    };

    return (

        <div>
            <Topbar title={"Create Job"} />
            <div className='page-container'>
                <Col sm={6}>
                    <Form onSubmit={handleSubmit}>

                        <Form.Group className="mb-3">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="New Vat"
                                onChange={e => handleFormChange('name', e)}
                                isInvalid={!!formError.name}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Job Code: </Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="XXX XXX XXX"
                                onChange={e => handleFormChange('jobCode', e)}
                                isInvalid={!!formError.jobCode} />
                            <Form.Control.Feedback type='invalid'>
                                {formError.jobCode}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Client:</Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="Don's Winery"
                                onChange={e => handleFormChange('client', e)}
                                isInvalid={!!formError.client} />
                            <Form.Control.Feedback type='invalid'>
                                {formError.client}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Start Date:</Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="2021/11/23"
                                onChange={e => handleFormChange('startDate', e)}
                                isInvalid={!!formError.startDate}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.startDate}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Address:</Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="123 Wine Dr"
                                onChange={e => handleFormChange('address', e)}
                                isInvalid={!!formError.address}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.address}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>City:</Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="Kyneton"
                                onChange={e => handleFormChange('city', e)}
                                isInvalid={!!formError.city}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.city}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>State:</Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="Victoria"
                                onChange={e => handleFormChange('state', e)}
                                isInvalid={!!formError.state}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.state}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Postcode:</Form.Label>
                            <Form.Control
                                id="inlineFormInputName"
                                placeholder="3444"
                                onChange={e => handleFormChange('postcode', e)}
                                isInvalid={!!formError.postcode}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {formError.postcode}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Project Status:</Form.Label>
                            <Form.Select onChange={(e) => { handleFormChange('status', e) }} isInvalid={!!formError.status} defaultValue='' >
                                <option value="" disabled>Select Type</option>
                                <option value="In Progress">In Progress</option>
                                <option value="On Hold">On Hold</option>
                                <option value="Closed">Closed</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                                {formError.status}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <AssignedForms />

                        <SignOnRequirements />

                        <SiteRestrictions />

                        <SiteRequiredQualifications />

                        <AssignedWorkers />

                        <Button type="submit" variant="primary">
                            Create Job
                        </Button>
                    </Form>
                </Col>

            </div>
        </div>
    );
}


export default CreateJob;