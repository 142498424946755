

const validators = {

    validateFirstname: (fName) => {
        if (!fName || fName === '') return { isValid: false, errorMessage: 'First name cannot be blank.' }

        return {
            isValid: true
        }
    },

    validateLastName: (lName) => {
        if (!lName || lName === '') return { isValid: false, errorMessage: 'Last name cannot be blank' }

        return {
            isValid: true
        }
    },

    validateEmail: (email) => {
        const isValidEmail = (val) => /.+@.+\..+/.test(val);

        if (!email || !isValidEmail(email)) return { isValid: false, errorMessage: 'Email format is invalid' };

        return {
            isValid: true
        }
    },

    validatePhone: (phone) => {
        if (!phone || phone.length !== 10) return { isValid: false, errorMessage: 'Phone number is invalid' }

        return {
            isValid: true
        }
    },
    
    validateUserType: (type) => {
        if (!type || type === '') return { isValid: false, errorMessage: 'Please select an user type' }

        return {
            isValid: true
        }
    },

    validatePassword: (pwd) => {
        const hasNum = /\d/.test(pwd);
        if (!pwd) return { isValid: false, errorMessage: 'Password cannot be empty' };
        if (pwd.length < 8 || !hasNum) return { isValid: false, errorMessage: 'Password must have at least 8 characters and must contain one digit.' }
        
        return {
            isValid: true
        }
    },
    validateExpireDate: (date) => {
        if(!date || date ==='') return {isValid: false, errorMessage: 'date is invalid'}
        try {
            const expireDate = new Date(date);
            if (expireDate < Date.now()) return { isValid: false, errorMessage: 'Expire date cannot be past',}
        }
        catch (e) {
            return {isValid: false, errorMessage: 'date fomat error'}
        }

        return {isValid: true}
        
    },
    validateQualificationType: (name) => {
        if (!name || name === '') return { isValid: false, errorMessage: 'Qualification name cannot be empty' }

        return {
            isValid: true,
        }
    },
    validateFileExtension: (file) => {
        const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
        if (!re.exec(file)) return { isValid: false, errorMessage: 'File extension unsupported.' }
        
        return {
            isValid: true,
        }
    },
    validateAbn: (abn) => {
        if (!abn || abn === '') return { isValid: false, errorMessage: 'ABN cannot be empty' }

        return {
            isValid: true,
        }
    },
    validateGenericString: (input) => {
        if (!input || input === '') return { isValid: false, errorMessage: 'Input name cannot be blank.' }

        return {
            isValid: true,
        }
    },

    validatePostcode: (postcode) => {
        if (isNaN(postcode)) return { isValid: false, errorMessage: 'Postcode has to be number' };

        if (!isNaN(postcode) && (postcode.length !== 4)) return { isValid: false, errorMessage: 'Postcode has to be four digits' }

        return {
            isValid: true,
        }
    }
}

export default validators;