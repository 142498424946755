
import { useAllUsers } from "./queries/useAllUsersQuery";


const useUsers = () => {
    const { users, loading } = useAllUsers();
    return {
        users,
        loading,
    };
};

export default useUsers;