import SidebarProfileImage from "./sidebar-profile-image";
import { useRealmApp } from "../../providers/realm/realmApp";
import MenuList from "./menu-list";
import CompanyLogo from "../common/companyLogo";
import useUserByAppUserId from "../../hooks/users/useUserByAppUserId";
import theme from "../../themes/theme";

const styles = {
    sidebar: {
        width: "100%",
        height: "100vh",
        borderRight: `1px solid ${theme.grey} `
    }
}

function Sidebar() {

    const { currentUser, logOut } = useRealmApp();

    const {user, loading} = useUserByAppUserId(currentUser.id);

    return (
        <div className="flex-shrink-0 p-3 bg-white" style={styles.sidebar}>
            <CompanyLogo />
            <SidebarProfileImage currentUser={{...currentUser, "meta": user}}/>
            <MenuList currentUser={{...currentUser, "meta": user}} handleLogout={logOut} />
        </div>
    );
}

export default Sidebar;

