import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import validators from '../../helpers/validators'
import { dummySignOnRequirements } from '../dummies/dummySignOnRequirements'

const SignOnRequirementsModal = ({ modalMode, ...props }) => {

    //use useEffect to call API
    const findData = (id) => {
        return dummySignOnRequirements.filter((data, index) => {
            return data.id === id;
        });
    };

    const [modalData, setModalData] = useState({});
    const [formError, setFormError] = useState({});
    useEffect(() => {
        const data = modalMode.mode === 'Edit' ? findData(modalMode.id)[0] : '';
        setModalData(data)
    }, [modalMode.mode, modalMode.id]);

    const findError = () => {
        const { type, description, dateValid, dateExpires } = modalData;

        const newErrors = {};

        const { isValid: typeValid, errorMessage: qTypeError } = validators.validateQualificationType(type);
        if (!typeValid) newErrors.type = qTypeError;

        if (!description || description === '') newErrors.description = 'Description cannot be empty';


        if (!dateValid || dateValid === '') newErrors.dateValid = 'Date cannot be empty';

        const { isValid: expireDateValid, errorMessage: expireDateError } = validators.validateExpireDate(dateExpires);
        if (!expireDateValid) newErrors.expireDate = expireDateError;

        return newErrors;
    }

    const handleModalSubmit = (e) => {
        e.preventDefault();

        const errors = findError();

        if (Object.keys(errors).length > 0) {
            setFormError(errors);
            return;
        }

        setFormError({});

        if (modalMode.mode === 'Add') {
            console.log('added', modalData);
        }
        else {
            console.log('edited', modalData);
        }
    }

    const handleModalChange = (name, event) => {

        setModalData({
            ...modalData,
            [name]: event.target.value,
        })
    }




    return (
        <Modal centered size='lg' {...props}>
            <Modal.Header closeButton>
                {modalMode.mode} Required Sign On
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3'>
                        <Form.Label>Date</Form.Label>
                        <Form.Control id='name' isInvalid={!!formError.date} value={modalData.date ?? ''} onChange={(e) => handleModalChange('date', e)} disabled/>
                        <Form.Control.Feedback type='invalid'>{formError.date}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Sign On Requirement</Form.Label>
                        <div class="overflow-auto" style={{ height: "150px", padding: "10px", border: "1px solid #ced4da", borderRadius: "0.25rem" }}>
                            <Form.Check
                                label="Breath Test"
                                name="group1"
                                type="checkbox"
                                checked
                            />
                            <Form.Check
                                label="Medication"
                                name="group1"
                                type="checkbox"
                                checked
                            />
                            <Form.Check
                                label="Packing List"
                                name="group1"
                                type="checkbox"
                                checked
                            />
                            <Form.Check
                                label="Safe Work Method Statement"
                                name="group1"
                                type="checkbox"
                                checked
                            />

                            <Form.Check
                                label="JSA's"
                                name="group1"
                                type="checkbox"
                                checked
                            />
                            <Form.Check
                                label="Risk Assessment"
                                name="group1"
                                type="checkbox"
                                checked
                            />
                            <Form.Check
                                label="HSE"
                                name="group1"
                                type="checkbox"
                                checked
                            />
                        </div>
                    </Form.Group>

                    <Modal.Footer>
                        <Button onClick={e => handleModalSubmit(e)} variant="primary mt-5">
                            {modalMode.mode === 'Add' ? 'Add' : 'Save'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>

        </Modal>
    )
}

export default SignOnRequirementsModal;