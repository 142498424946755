import React, {useEffect, useState} from 'react';
import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';
import clients from '../../components/dummies/dummyClients';
import { Link } from "react-router-dom";

const getAllClients = () => {
    return clients;
}

const Paginator = ({ ...props }) => {

    return (
        <div>
            <span style={{ marginRight: "10px", color: "#0d6efd" }}>5 / 50</span>
            <a href="/clients?page=1" style={{ marginRight: "10px" }}>1</a>
            <a href="/clients?page=2" style={{ marginRight: "10px" }}>2</a>
            <a href="/clients?page=3" style={{ marginRight: "10px" }}>3</a>
            <a href="/clients?page=4" style={{ marginRight: "10px" }}>4</a>
            <a href="/clients?page=5" style={{ marginRight: "10px" }}>5</a>
            <a href="/clients?page=6" style={{ marginRight: "10px" }}>6</a>
            <a href="/clients?page=7" style={{ marginRight: "10px" }}>7</a>
            <a href="/clients?page=8" style={{ marginRight: "10px" }}>8</a>
            <a href="/clients?page=9" style={{ marginRight: "10px" }}>9</a>
            <a href="/clients?page=10" style={{ marginRight: "10px" }}>10</a>
        </div>
    );
    
}

const ClientTable = ({clients}) => {
    
    return (
        <div>
            <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Number</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {clients.map(client => {
                        return (
                            <tr key={client.id}>
                                <td>{client.name}</td>
                                <td>{client.email}</td>
                                <td>{client.phone}</td>
                                <td><Link to={{ pathname : `/client/${client.id}`}}>View</Link></td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}



const Clients = () => {
    document.title = title('Clients');
    const [clients, setClients] = useState([]);
    
    useEffect(() => {
        const clients = getAllClients();

        setClients(clients);
    }, []);

    return (
        <div>
            <Topbar title={"Clients"} />
            
            <div className='page-container'>
                <Col sm={6} >
                    <Form className="mb-5">
                        <Row className="align-items-center">
                            <Col sm={6} className="my-1">
                                <Form.Control id="inlineFormInputName" placeholder=" Search..." />
                            </Col>
                            <Col xs="auto" className="my-1">
                                <Button type="submit">Find</Button>
                            </Col>
                        </Row>
                    </Form>

                    <ClientTable clients={clients} />

                    <Paginator />
                </Col>

            </div>
        </div>
    )
}

export default Clients;