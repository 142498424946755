import React, {useState, useEffect} from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const ClientUserModal = ({ modalState, onModalSave, ...props }) => {
    const [modalData, setModalData] = useState({});
    const [formError, setFormError] = useState({});

    useEffect(() => {
        setFormError({})
        setModalData(modalState.data);
    }, [modalState])

    const findError = formData => {
        const errors = {};
        const { name } = formData;
        console.log(formData)
        if (!name || name === '') errors.name = 'User cannot be empty.';

        return errors;
    }
    

    const handleModalChange = e => {

        setModalData({
            ...modalData,
            [e.target.name]:e.target.value
        })
    }

    const handleButtonClick = e => {
        e.preventDefault();

        const errors = findError(modalData);
        if ((Object.keys(errors).length > 0)) {
            setFormError(errors);
            return;
        }

        //passed form validation
        setFormError({});

        onModalSave(modalData);
    }
    
    return (
        <div>
            <Modal centered size='lg' {...props}>
                <Modal.Header closeButton>
                    {modalState.mode} User
                </Modal.Header>
                
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>User Name</Form.Label>
                            <Form.Control name='name' isInvalid={!!formError.name} value={modalData.name || ''} onChange={handleModalChange}></Form.Control>
                            <Form.Control.Feedback type='invalid'>{ formError.name }</Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='primary' onClick={handleButtonClick}>
                        {modalState.mode === 'Add' ? 'Add' : 'Ok'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


export default ClientUserModal;