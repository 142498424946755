import React from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../../providers/realm/realmApp";
import { toggleBoolean } from "../../utils";
import { useErrorAlert } from "../../hooks/useErrorAlert";
import { title } from '../../helpers/title';

import { Container, Row, Col, Button, Form } from 'react-bootstrap';

function Login() {

    document.title = title('Login');

    const realmApp = useRealmApp();
    // Authentication errors
    const noErrors = {
        email: null,
        password: null,
        other: null,
    };
    const [error, setError] = React.useState(noErrors);
    const clearErrors = () => setError(noErrors);
    const NonAuthErrorAlert = useErrorAlert({
        error: error.other,
        clearError: () => {
            setError((prevError) => ({ ...prevError, other: null }));
        },
    });

    const onFormSubmit = async ({ email, password }) => {
        clearErrors();
        try {
            await realmApp.logIn(Realm.Credentials.emailPassword(email, password));
        } catch (err) {
            handleAuthenticationError(err, setError);
        }
    };

    return (
        <Container style={{ display: "flex", height: "100vh" }}>
            <Row style={{ marginTop: "auto", marginBottom: "auto", width: "100%" }}>
                <Col md={{ span: 3, }} style={{ margin: "auto" }}>

                    <img src="/twa-logo.png" alt="" style={{width: "100%", marginBottom: "10px"}} />

                    <Form className="auth-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const { email, password } = Object.fromEntries(formData.entries());
                            onFormSubmit({ email, password });
                        }}>
                        <h2>
                            Log In
                        </h2>
                        <NonAuthErrorAlert />

                        <Form.Group className="mb-3" controlId="formLoginEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                name="email"
                            // error={Boolean(error.email)}
                            // helperText={error.email ?? ""}
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formLoginPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                            // error={Boolean(error.password)}
                            // helperText={error.password ?? ""}
                            />
                        </Form.Group>

                        <Button type="submit" variant="primary">
                            Log In
                        </Button>
                    </Form>

                </Col>
            </Row>
        </Container >
    );
}

function handleAuthenticationError(err, setError) {
    const handleUnknownError = () => {
        setError((prevError) => ({
            ...prevError,
            other: "Something went wrong. Try again in a little bit.",
        }));
        console.warn(
            "Something went wrong with a Realm login or signup request. See the following error for details."
        );
        console.error(err);
    };
    if (err instanceof Realm.MongoDBRealmError) {
        const { error, statusCode } = err;
        const errorType = error || statusCode;
        switch (errorType) {
            case "invalid username":
                setError((prevError) => ({
                    ...prevError,
                    email: "Invalid email address.",
                }));
                break;
            case "invalid username/password":
            case "invalid password":
            case 401:
                setError((prevError) => ({
                    ...prevError,
                    password: "Incorrect password.",
                }));
                break;
            case "name already in use":
            case 409:
                setError((prevError) => ({
                    ...prevError,
                    email: "Email is already registered.",
                }));
                break;
            case "password must be between 6 and 128 characters":
            case 400:
                setError((prevError) => ({
                    ...prevError,
                    password: "Password must be between 6 and 128 characters.",
                }));
                break;
            default:
                handleUnknownError();
                break;
        }
    } else {
        handleUnknownError();
    }
}


export default Login;