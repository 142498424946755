import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../../providers/realm/realmApp";
import { toggleBoolean } from "../../utils";
import { useErrorAlert } from "../../hooks/useErrorAlert";
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';
import '../../views/index.css';


function CreateAsset(props) {

    document.title = title('Create User');

    const handleSubmit = (event) => {
        event.preventDefault();

        // var claimedOwner = event.target[0].value;

        // claimItem({ variables: { id: itemId, claimedOwner: claimedOwner, status: "claimed" } });

        // window.location.reload();

    };

    return (
        <div>
            <Topbar title={"Create Asset"} />

            <div className='page-container'>
                <Col sm={6}>
                    <Form onSubmit={handleSubmit}>
                        
                        <Form.Group className="mb-3">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control id="inlineFormInputName" placeholder="New Vat" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Job Code: </Form.Label>
                            <Form.Control id="inlineFormInputName" placeholder="XXX XXX XXX" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Client:</Form.Label>
                            <Form.Control id="inlineFormInputName" placeholder="Don's Winery" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Start Date:</Form.Label>
                            <Form.Control id="inlineFormInputName" placeholder="2021/11/23" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Address:</Form.Label>
                            <Form.Control id="inlineFormInputName" placeholder="123 Wine Dr, Kyneton" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Project Status:</Form.Label>
                            <Form.Control id="inlineFormInputName" placeholder="In progress" />
                        </Form.Group>

                        <Button variant="primary">
                            Create Asset
                        </Button>
                    </Form>
                </Col>

            </div>
        </div>
    );
}



export default CreateAsset;