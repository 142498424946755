import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GetUserByAppUserIdQuery = gql`
  query GetUserByAppUserId($app_user_id: ObjectId!) {
    user(query: { app_user_id: $app_user_id }) {
      _id
      app_user_id
      email
      first_name
      last_name
      phone_number
      type
      profile_picture
    }
  }
`;

export function useGetUserByAppUserId(id) {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    GetUserByAppUserIdQuery,
    {
      variables: {
        app_user_id: id,
      },
    }
  );

  const user = data?.user ?? {};
  return {
    user,
    loading,
  };
}