import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GetJobRestrictionsByJobId = gql`
  query GetJobRestrictionsByJobId($job : JobQueryInput) {
    jobRestrictions(query: { job_id: $job }) {
      _id
      name
    }
  }
`;

export function useGetJobRestrictionsByJobId(id) {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    GetJobRestrictionsByJobId,
    {
      variables: {
        job: {
          "_id": id
        },
      },
    }
  );

  const jobRestrictions = data?.jobRestrictions ?? [];
  return {
    jobRestrictions,
    loading,
  };
}