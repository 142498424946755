import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { title } from '../../../helpers/title';
import dateHelper from '../../../helpers/date';
import Topbar from '../../../components/topbar/topbar';
import '../../../views/index.css';

import { useQuery } from "@apollo/client";
import jobQueries from '../../../queries/job-queries';

function SignOnBreathTestEntryInfo({ entry }) {

    return (
        <Form>

            <Form.Group className="mb-3">
                <Form.Label>User:</Form.Label>
                <Form.Control id="inlineFormInputName" placeholder={entry.user_id.first_name + " " + entry.user_id.last_name} disabled />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Result:</Form.Label>
                <Form.Control id="inlineFormInputName" placeholder={entry.result} disabled />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Comment:</Form.Label>
                <Form.Control id="inlineFormInputName" placeholder={entry.comment} disabled />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Completed Time:</Form.Label>
                <Form.Control id="inlineFormInputName" placeholder={dateHelper.timestampToDateTimeString(parseInt(entry.complete_time)) } disabled />
            </Form.Group>

            <div className="mb-3">
                <p>Photo:</p>
                <img src={entry.image_url} alt="" style={{ width: "500px" }} />
            </div>

        </Form>
    );

}


function SignOnBreathTestEntries() {

    var path = window.location.pathname;

    var breathTestId = path.replace('/sign-on/breath-test/', '');

    document.title = title('Sign Ons | Breath Test Entry');

    const { data, loading, error } = useQuery(
        jobQueries.getJobSignOnBreathTestForId,
        {
            variables: {
                "_id": breathTestId
            },
        }
    );

    if (loading) {
        return (<></>)
    }

    return (
        <div>
            <Topbar title={"Sign Ons | Breath Test Entry"} />

            <div className='page-container'>
                <Col sm={6}>

                    <SignOnBreathTestEntryInfo entry={data.jobSignOnBreathTestEntry} />

                </Col>

            </div>
        </div>
    );
}

export default SignOnBreathTestEntries;