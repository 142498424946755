import React, {useState} from 'react';
import Topbar from '../../components/topbar/topbar';
import '../../views/index.css';
import { Col, Button, Form } from 'react-bootstrap';
import CustomFormSelect from '../../components/clients/customFormSelect';
import COUNTRY_STATE from '../../components/dummies/dummyCountryState';
import validate from '../../helpers/formValidators/clientFormValidator';

const CreateClient = () => {

    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState({});

    const handleFieldChange = e => {
        const fieldName = e.target.name;
        const fieldVal = e.target.value;

        setFormData({
            ...formData,
            [fieldName]: fieldVal
        })
    }

    const handleButtonClick = (e) => {
        e.preventDefault();
        const errors = validate(formData);
        console.log(errors)
        if (Object.keys(errors).length > 0) {
            setFormError(errors);
            return;
        }

        // pass the validation
        setFormError({});
        alert("Form saved, redirect to ??")
    }

    return (
        <div>
            <Topbar title="Create Client Profile" />
            <div className='page-container'>
                <Col sm={6}>
                    <Form>
                        <Form.Group className='mb-3'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control name="name" isInvalid={!!formError.name} value={formData.name || ''} onChange={handleFieldChange}></Form.Control>
                            <Form.Control.Feedback type='invalid'>{formError.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>ABN</Form.Label>
                            <Form.Control name="abn" isInvalid={!!formError.abn} value={formData.abn || ''} onChange={handleFieldChange}></Form.Control>
                            <Form.Control.Feedback type='invalid'>{formError.abn}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control name="email" isInvalid={!!formError.email} value={formData.email || ''} onChange={handleFieldChange}></Form.Control>
                            <Form.Control.Feedback type='invalid'>{formError.email}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control name="phone" isInvalid={!!formError.phone} value={formData.phone || ''} onChange={handleFieldChange}></Form.Control>
                            <Form.Control.Feedback type='invalid'>{formError.phone}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control name="address" isInvalid={!!formError.address} value={formData.address || ''} onChange={handleFieldChange}></Form.Control>
                            <Form.Control.Feedback type='invalid'>{formError.address}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>City</Form.Label>
                            <Form.Control name="city" isInvalid={!!formError.city} value={formData.city || ''} onChange={handleFieldChange}></Form.Control>
                            <Form.Control.Feedback type='invalid'>{formError.city}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>State</Form.Label>
                            <CustomFormSelect name="state" isInvalid={!!formError.state} value={formData.state || ''} options={COUNTRY_STATE.Australia} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{formError.state}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control name="postcode" isInvalid={!!formError.postcode} value={formData.postcode || ''} onChange={handleFieldChange}></Form.Control>
                            <Form.Control.Feedback type='invalid'>{formError.postcode}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Country</Form.Label>
                            <CustomFormSelect name="country" isInvalid={!!formError.country} value={formData.country || ''} options={Object.keys(COUNTRY_STATE)} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{formError.country}</Form.Control.Feedback>
                        </Form.Group>
                        <Button variant='primary' onClick={handleButtonClick}>
                            Add
                        </Button>
                    </Form>
                </Col>
            </div>
        </div>
    )
}


export default CreateClient;