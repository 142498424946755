

// Should Date expires be nullable? given some licence may have not expire date.
export const dummySignOnRequirements = [
    {
        id: '001',
        date: '2021/12/01',
        requiredSignOns : [
            'Breath Test',
            'Medication',
            'Packing List',
            'Safe Work Method Statement',
            'JSA\'s',
            'Risk Assessment',
            'HSE',
        ]
    },
]