import gql from "graphql-tag";

const jobQueries = {

    getJobSignOnEntriesForJobId: gql`
        query GetJobSignOnEntriesForJobId($job: JobQueryInput!) {
            jobSignOnEntries(query: { job_id: $job }) {
                _id
                job_id {
                _id
                }
                date
            }
        }
    `,

    getJobSignOnBreathTestsForJobSigOnEntryId: gql`
        query GetJobSignOnBreathTestForJobSigOnEntryId($JobSignOnEntry: JobSignOnEntryQueryInput) {
            jobSignOnBreathTestEntries(query: { job_sign_on_entry_id: $JobSignOnEntry }) {
                _id
                complete_time
                image_url
                user_id {
                    first_name
                    last_name
                }
            }
        }
    `,

    getJobSignOnBreathTestForId: gql`
        query GetJobSignOnBreathTestForId($_id: ObjectId!) {
            jobSignOnBreathTestEntry(query: { _id: $_id }) {
                _id
                user_id {
                    _id
                    first_name
                    last_name
                }
                result
                comment
                image_url
                complete_time
            }
        }
    `,

    getJobSignOnRequirementForDate: gql`
        query GetJobSignOnRequirementsByDate($timestamp: String!) {
            jobSignOnRequirements(query: { date: $timestamp }) {
                _id
                date
            }
        }
    `,


    getJobSignOnFormEntryForJobSigOnEntryId: gql`
        query GetJobSignOnFormEntryForJobSigOnEntryId($JobSignOnEntry: JobSignOnEntryQueryInput, $type : String!) {
            jobSignOnFormEntries(query: { job_sign_on_entry_id: $JobSignOnEntry, type : $type }) {
                _id
                type
                content
            }
        }
    `,

    getJobSignOnFormForId: gql`
        query GetJobSignOnFormForId($_id: ObjectId!) {
            jobSignOnFormEntry(query: { _id: $_id }) {
                _id
                type
                content
            }
        } 
    `



}

export default jobQueries;