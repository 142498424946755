import React, {useState, useEffect} from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { dummyQualifications } from '../dummies/dummyQualifications'
import validators from '../../helpers/validators'

const QualificationModal = ({ modalMode, ...props}) => {

    //use useEffect to call API
    const findData = (id) => {
        return dummyQualifications.filter((data, index) => {
            return data.id === id;
        });
    };

    const [modalData, setModalData] = useState({});
    const [formError, setFormError] = useState({});
    useEffect(() => {
        const data = modalMode.mode === 'Edit' ? findData(modalMode.id)[0] : '';
        setModalData(data)
    }, [modalMode.mode, modalMode.id]);

    const findError = () => {
        const { type, description, dateValid, dateExpires } = modalData;

        const newErrors = {};

        const { isValid: typeValid, errorMessage: qTypeError } = validators.validateQualificationType(type) ;
        if (!typeValid) newErrors.type = qTypeError;

        if (!description || description === '') newErrors.description = 'Description cannot be empty';
        

        if (!dateValid || dateValid === '') newErrors.dateValid = 'Date cannot be empty';

        const { isValid: expireDateValid, errorMessage: expireDateError } = validators.validateExpireDate(dateExpires) ;
        if (!expireDateValid) newErrors.expireDate = expireDateError;

        return newErrors;
    }
    
    const handleModalSubmit = (e) => {
        e.preventDefault();
        
        const errors = findError();

        if (Object.keys(errors).length > 0) {
            setFormError(errors);
            return;
        }

        setFormError({});

        if (modalMode.mode === 'Add') {
            console.log('added', modalData);
        }
        else {
            console.log('edited', modalData);
        }
    }

    const handleModalChange = (name, event) => {

        setModalData({
            ...modalData,
            [name]: event.target.value,
        })        
    }




    return (
        <Modal centered size='lg' {...props}>
            <Modal.Header closeButton>
                {modalMode.mode} Qualification
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control id='name' isInvalid={!!formError.type} value={modalData.type ?? ''} onChange={(e) => handleModalChange('type', e)} /> 
                        <Form.Control.Feedback type='invalid'>{ formError.type }</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>Descrtption / Number</Form.Label>
                        <Form.Control id='desc' isInvalid={!!formError.description} value={modalData.description ?? ''} onChange={(e) => handleModalChange('description', e)} /> 
                        <Form.Control.Feedback type='invalid'>{ formError.description }</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>Date Valid</Form.Label>
                        <Form.Control id='validDate' isInvalid={!!formError.dateValid} value={modalData.dateValid ?? ''} onChange={(e) => handleModalChange('dateValid', e)} /> 
                        <Form.Control.Feedback type='invalid'>{ formError.dateValid }</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>Date Expires</Form.Label>
                        <Form.Control id='expireDate' isInvalid={!!formError.expireDate} value={modalData.dateExpires ?? ''} onChange={(e) => handleModalChange('dateExpires', e)} /> 
                        <Form.Control.Feedback type='invalid'>{ formError.expireDate }</Form.Control.Feedback>
                    </Form.Group>

                    <Modal.Footer>
                        <Button onClick={e => handleModalSubmit(e)} variant="primary mt-5">
                                {modalMode.mode === 'Add' ? 'Add': 'Save'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
            
        </Modal>
    )
}

export default QualificationModal;