import React from 'react';

const styles = {
    logo: {
        display: 'flex',
        justifyContent:'center'
    }
}

const CompanyLogo = () => {
    const logo = require('../../images/logo.png');
    return (
        <div style={styles.logo}>
            <img src={logo} alt='company logo'/>
        </div>
    )
}


export default CompanyLogo;