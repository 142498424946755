
const clients = [
    {   
        id: "001",
        name: "Don's winery",
        email: "joe.blogs@example.com",
        phone: "1111111111",
        abn: "34 557 778 998",
        address: "123 Wine Dr",
        city: "Kyneton",
        state: "Victoria",
        postcode: "3444",
        country: "Australia"
    },
    {   
        id: "002",
        name: "Don's winery",
        email: "joe.blogs@example.com",
        phone: "2222222222",
        abn: "34 557 778 998",
        address: "123 Wine Dr",
        city: "Kyneton",
        state: "Victoria",
        postcode: "3444",
        country: "Australia"
    },
    {   
        id: "003",
        name: "Don's winery",
        email: "joe.blogs@example.com",
        phone: "3333333333",
        abn: "34 557 778 998",
        address: "123 Wine Dr",
        city: "Kyneton",
        state: "Victoria",
        postcode: "3444",
        country: "Australia"
    },
    {   
        id: "004",
        name: "Don's winery",
        email: "joe.blogs@example.com",
        phone: "4444444444",
        abn: "34 557 778 998",
        address: "123 Wine Dr",
        city: "Kyneton",
        state: "Victoria",
        postcode: "3444",
        country: "Australia"
    },
];


export default clients;
