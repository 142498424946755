
import React, {useState} from 'react';
import { Table, Button } from 'react-bootstrap';
import QualificationModal from '../../components/modal/QualificationModal';
//{string, list}

const QualificationTable = ({ qualifications}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('');

    const handleEdit = (id) => {
        setModalOpen(true);
        setModalMode({mode:'Edit', id:id})
    }

    const addQualification = () => {

        setModalOpen(true);
        setModalMode({ mode: 'Add' });
    }

    return (
        <div>
            <p>Qualifications</p>
            <Table responsive bordered hover site='sm'>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Date Valid</th>
                        <th>Date Expires</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {qualifications.map(q => {
                        return (
                            <tr key={q.id}>
                                <td>{q.type}</td>
                                <td>{q.description }</td>
                                <td>{q.dateValid}</td>
                                <td>{q.dateExpires}</td>
                                <th><Button onClick={() => handleEdit(q.id)}>Edit</Button></th>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            <Button variant='primary' onClick={addQualification}>
                Add
            </Button>

            
            <QualificationModal
                show={modalOpen}
                modalMode={modalMode}
                onHide={() => setModalOpen(false)}
            />
        </div>
        
    )

}

export default QualificationTable;