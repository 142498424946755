import { Button, Form, Col } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from "react";
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';
import '../../views/index.css';
import { useParams } from 'react-router-dom';
import { dummyAssets } from '../../components/dummies/dummyAssets';
import ServiceTable from '../../components/assets/ServiceTable';
import validators from '../../helpers/validators';
import validateAssetForm from '../../helpers/formValidators/assetFormValidator';


const styles = {
    roundedLayer: {
        display: "block",
        position: "relative",
        width: "200px",
        height: "200px",
        overflow: "hidden",
        borderRadius: "50%",
        marginLeft: "50px",
    },
    roundedLayerImage: {
        width: "auto",
        height: "100%"
    }

}

const findAsset = (id) => {
    return dummyAssets.find(asset => {
        return asset.id === id;
    });
}

const Asset = ({ props }) => {
    const { assetId } = useParams();
    document.title = title(`Job | ${assetId}`);
    
    const [asset, setAsset] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [imageUrl, setImgUrl] = useState('');
    const [fileExtensionError, setFileExtensionError] = useState('');
    const [formError, setFormError] = useState({});

    
    const handleAssetChange = (e) => {
        setAsset({
            ...asset,
            [e.target.name]: e.target.value,
        })
    }

    useEffect(() => {
        const asset = findAsset(assetId);
        setAsset(asset);
        setImgUrl(asset.photoUrl || '');
    }, [assetId]);

    const handleImageUpdate = (event) => {
        const fileName = event.target.files[0].name;
        const validateResult = validators.validateFileExtension(fileName);

        if (!validateResult.isValid) {
            setFileExtensionError(validateResult.errorMessage);
            return;
        }

        setFileExtensionError('');
        setImgUrl(URL.createObjectURL(event.target.files[0]));
        
    }


    const resetFormErrors = () => {
        setFormError({});
        setFileExtensionError('');
    }

    const handleClick = (e) => {
        //if the edit button is clicked, only need enable input fields
        if (!editMode) {
            setEditMode(!editMode);
            return;
        }
        
        e.preventDefault();
        //drop serviceSchedule
        const newRecord = Object.assign({}, asset);
        newRecord.imageUrl = imageUrl;
        delete newRecord.serviceSchedule;

        const errors = validateAssetForm(newRecord);

        if ((Object.keys(errors).length > 0) || (fileExtensionError)) {
            console.log('fileError', fileExtensionError)
            setFormError(errors);
            return;
        }

        resetFormErrors();
        
        //if input fields are enabled, handle submission
        alert('Updated' + JSON.stringify(newRecord));
        setEditMode(!editMode);

    }


    
    return (
        <div>
            <Topbar title="Asset" />
            <div className='page-container'>
                <Col sm={6}>
                    <Form >
                        <Form.Group className='mb-3'>
                            <Form.Label>Photo:</Form.Label>

                            <div className="mb-3" style={{ display: "flex" }}>
                                <div style={styles.roundedLayer}>
                                    <img src={imageUrl || ''} alt="" style={styles.roundedLayerImage} />
                                </div>
                            </div>
                            {editMode &&
                                <div>
                                    <Form.Control type='file' onChange={handleImageUpdate} isInvalid={!!fileExtensionError} />
                                    <Form.Control.Feedback type='invalid' >{ fileExtensionError }</Form.Control.Feedback>
                                </div>
                            }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Asset Name:</Form.Label>
                            <Form.Control name="name" isInvalid={!!formError.name} value={asset.name || ''} disabled={!editMode} onChange={handleAssetChange} />
                            <Form.Control.Feedback type='invalid'> { formError.name }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Make:</Form.Label>
                            <Form.Control id="inlineFormInputName" isInvalid={!!formError.make} name="make" value={asset.make || ''} disabled={!editMode} onChange={handleAssetChange} />
                            <Form.Control.Feedback type='invalid'> { formError.make }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Model:</Form.Label>
                            <Form.Control id="inlineFormInputName" isInvalid={!!formError.model} name="model" value={asset.model || ''} disabled={!editMode} onChange={handleAssetChange} />
                            <Form.Control.Feedback type='invalid'> { formError.model }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Year:</Form.Label>
                            <Form.Control id="inlineFormInputName" name='year' isInvalid={!!formError.year} disabled={!editMode} value={asset.year || ''} onChange={handleAssetChange} />
                            <Form.Control.Feedback type='invalid'> { formError.year }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Rego: </Form.Label>
                            <Form.Control id="inlineFormInputName" name='rego' isInvalid={!!formError.rego} value={asset.rego || ''} disabled={!editMode} onChange={handleAssetChange} />
                            <Form.Control.Feedback type='invalid'> { formError.rego }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Odometer (km):</Form.Label>
                            <Form.Control id="inlineFormInputName" name='odometer' isInvalid={!!formError.odometer} value={asset.odometer || ''} disabled={!editMode} onChange={handleAssetChange} />
                            <Form.Control.Feedback type='invalid'> { formError.odometer }</Form.Control.Feedback>
                        </Form.Group>
                        
                        <Button variant='primary' onClick={handleClick}>
                            {editMode ? 'Save' : 'Edit'}
                        </Button>
                    </Form>

                    <ServiceTable assetId={assetId} services={asset.serviceSchedule || []} />
                </Col>

            </div>
        </div>
    );
}



export default Asset;
