import Login from "../../views/login/login";
import DashboardLayout from "../dashboard-layout/dashboard-layout";
import SignOnLayout from "../sign-on-layout/sign-on-layout"
import { RealmAppProvider, useRealmApp } from "../../providers/realm/realmApp";
import RealmApolloProvider from "../../providers/realm/realmApolloProvider";
import realmJson from "../../realm.json";
import useUserByAppUserId from "../../hooks/users/useUserByAppUserId";

const appId = realmJson.appId;

export default function AppWithRealm() {
  return (
    <RealmAppProvider appId={appId}>
      <RealmApolloProvider>
        <App />
      </RealmApolloProvider>
    </RealmAppProvider>
  );
}

function App() {
  const { currentUser, logOut } = useRealmApp();


  const { user, loading } = useUserByAppUserId((currentUser)? currentUser.id : "");

  if(loading){
    return (<></>)
  }
  

  console.log(currentUser);

  return (
    <div className="App">
      {currentUser && user ?

        ((user.type == 'Employee')? <SignOnLayout />: <DashboardLayout />)
        :

        <Login />
      }
    </div>
  );
}
