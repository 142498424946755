import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';
import '../../views/index.css';
import { dummyAssets } from '../../components/dummies/dummyAssets';
import AssetsTable from '../../components/assets/AssetsTable';

function AssetsPagination({ assets }) {

    const assetCount = assets.length;


    return (
        <div>
            <span style={{ marginRight: "10px", color: "#0d6efd" }}>{assetCount} / {assetCount}</span>

            <Link to={{ pathname: "/assets?page=1"}} style={{ marginRight: "10px" }}>1</Link>
        </div>
    );


}

function Assets(props) {

    document.title = title('Assets');

    return (

        <div>
            <Topbar title={"Assets"} />

            <div className='page-container'>
                <Col sm={6}>
                    <Form className="mb-5">
                        <Row className="align-items-center">
                            <Col sm={6} className="my-1">
                                <Form.Control id="inlineFormInputName" placeholder=" Search..." />
                            </Col>
                            <Col xs="auto" className="my-1">
                                <Button type="submit">Find</Button>
                            </Col>
                        </Row>
                    </Form>

                    <AssetsTable assets={dummyAssets} />


                    <AssetsPagination assets={dummyAssets} />
                </Col>

            </div>
        </div>


    );
}



export default Assets;