import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Sidebar from "../sidebar/sidebar";

import { 
  DashboardMap, 
  DashboardCalendar, 
  User, 
  EditUser, 
  Users, 
  CreateUser, 
  Job, 
  Jobs, 
  CreateJob, 
  EditJob, 
  SignOns, 
  SignOnBreathTestEntries, 
  SignOnBreathTestEntry, 
  SignOnFormEntry, 
  Assets, 
  CreateAsset, 
  Asset, 
  EmployeeWelcome, 
  EmployeeSignOnList, 
  EmployeeSignOnBreathTest,Client,
  Clients,
  CreateClient } from "../../views";

import { useMediaQuery } from "react-responsive";
import OffCanvasSidebar from '../sidebar/off-canvas-sidebar';

function DashboardLayout() {
  const tabletSize = useMediaQuery({ query: '(max-width: 768px)' });

  const styles = {
    container: {
      display: 'flex',
      flexDirection: tabletSize ? 'column' : 'row',
    },
    sidebar: {
      width: tabletSize ? "100%" : "350px"
    }
  }

  return (
    <Router>
    <div style={styles.container}>
      <div style={styles.sidebar}>
        {tabletSize ? <OffCanvasSidebar /> : <Sidebar />}
      </div>

      <div style={{ width: "100%", height: "100vh" }}>
        
          <Routes>
            <Route path="/" element={<DashboardMap />} />
            <Route path="/calendar" element={<DashboardCalendar />} />
            <Route path="/user/:userId" element={<User />} />
            <Route path="/user/edit/:userId" element={<EditUser />} />
            <Route path="/users" element={<Users />} />
            <Route path="/user/create" element={<CreateUser />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/job/:jobId" element={<Job />} />
            <Route path="/job/edit/:jobId" element={<EditJob />} />
            <Route path="/job/create" element={<CreateJob />} />
            <Route path="/sign-on/:signOnId" element={<SignOns />} />
            <Route path="/sign-on/breath-tests/:signOnId" element={<SignOnBreathTestEntries />} />
            <Route path="/sign-on/breath-test/:signOnBreathTestId" element={<SignOnBreathTestEntry />} />
            <Route path="/sign-on/form/:formId" element={<SignOnFormEntry />} />

            <Route path="/assets" element={<Assets />} />
            <Route path="/asset/:assetId" element={<Asset />} />
            <Route path="/asset/create" element={<CreateAsset />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/client/:clientId" element={<Client />} />
            <Route path="/client/create" element={<CreateClient />} />

            <Route path="/employee-welcome" element={<EmployeeWelcome />} />
            <Route path="/employee-sign-on-list" element={<EmployeeSignOnList />} />

            <Route path="/employee-sign-on-breath-test" element={<EmployeeSignOnBreathTest />} />
          </Routes>
      </div>
    </div>
    </Router>
  );
}



export default DashboardLayout;