import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';
import useJobs from "../../hooks/jobs/useJobs";
import '../../views/index.css';
import { Link } from "react-router-dom";

function JobRow({ jobs }) {

    

    if (jobs.length > 0) {
        return jobs.map(({ _id, name, client_id, job_code, start_date, address, city, state, postcode, country }) => (
            <tr>
                <td>{name}</td>
                <td>{client_id.name}</td>
                <td>{job_code.toUpperCase()}</td>
                <td>{start_date}</td>
                <td><a href={ "https://www.google.com/search?q=" + address + ", " + city + ", " + state + ", " + postcode + ", " + country} target="_blank">{address + ", " + city + ", " + state + ", " + postcode + ", " + country}</a></td>
                <td><Link to={{ pathname: `/job/${_id}`}}>View</Link></td>
            </tr>
        ));
    } else {
        return (
            <div style={{ color: 'white', textAlign: 'center' }} >
                <p>No Items found.</p>
            </div>
        )
    }

}

function JobsPagination({ jobs }) {

    const jobCount = jobs.length;


    return (
        <div>
            <span style={{ marginRight: "10px", color: "#0d6efd" }}>{jobCount} / {jobCount}</span>

            <Link to={{ pathname: "/jobs?page=1"}} style={{ marginRight: "10px" }}>1</Link>
        </div>
    );


}


function Jobs() {

    document.title = title('Jobs');

    const { jobs, loading } = useJobs();

    return (
        <div>
            <Topbar title={"Jobs"} />

            <div className='page-container'>
                <Col sm={6}>

                    <Form className="mb-5">
                        <Row className="align-items-center">
                            <Col sm={6} className="my-1">
                                <Form.Control id="inlineFormInputName" placeholder=" Search..." />
                            </Col>
                            <Col xs="auto" className="my-1">
                                <Button type="submit">Find</Button>
                            </Col>
                        </Row>
                    </Form>

                    <Table responsive bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Client</th>
                                <th>Job Code</th>
                                <th>Start Date</th>
                                <th>Address</th>
                                <th>Edit / View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <JobRow jobs={jobs} />
                        </tbody>
                    </Table>

                    <JobsPagination jobs={jobs}/>
                </Col>

            </div>
        </div>
    );
}

export default Jobs;