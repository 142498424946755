import React, {useState} from "react";
import { useRealmApp } from "../../providers/realm/realmApp";
import { Offcanvas } from "react-bootstrap";
import Hamburger from "./Hamburger";
import SidebarProfileImage from "./sidebar-profile-image";
import MenuList from "./menu-list";
import useUserByAppUserId from "../../hooks/users/useUserByAppUserId";
import CompanyLogo from "../common/companyLogo";
import theme from "../../themes/theme";
const styles = {
    container: {
        display: 'flex', 
        height: 'auto',
        borderBottom: `2px solid ${theme.grey}`,
    },
    burgerContainer: {
        padding: '5px',
        width:'10%',
        borderRight: `2px solid ${theme.grey}`,
    },
    headerContainer: {
        display: 'flex',
        flexGrow: '1',
        justifyContent: 'center',
        alignItems: 'center'
    },
    profileContainer: {
        borderBottom: `2px solid ${theme.grey}`,
    },
    profileImg: {
        padding: '15px 0'
    }
}


const OffCanvasSidebar = () => {
    const { currentUser, logOut } = useRealmApp();
    const {user, loading} = useUserByAppUserId(currentUser.id);

    const [show, setShow] = useState(false);

    const handleOpen = () => {
        setShow(!show);
    }

    return (
        <>        
            <div style={styles.container}>
                <div style={styles.burgerContainer}>
                    <Hamburger onClick={handleOpen}/>
                </div>
                <div style={styles.headerContainer}>
                    Xarc
                </div>
            </div>
            <div style={styles.profileContainer}>
            <CompanyLogo />
            <SidebarProfileImage currentUser={{...currentUser, "meta": user}} style={styles.profileImg}/>
            </div>
            
            <Offcanvas show={show} onHide={handleOpen}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <MenuList currentUser={{...currentUser, "meta": user}} handleLogout={logOut}/>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}


export default OffCanvasSidebar;