
const COUNTRY_STATE = {
    "Australia": [
        "New South Wales",
        "Queensland",
        "Northern Territory",
        "Western Australia",
        "South Australia",
        "Victoria",
        "Australian Capital Territory",
        "Tasmania"
    ]
};

export default COUNTRY_STATE;