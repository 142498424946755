import React from 'react';
import { Link } from "react-router-dom";
import { Accordion, useAccordionButton } from 'react-bootstrap';
import theme from '../../themes/theme';
import './sidebar.css';


const styles = {
    menuBorder: {
        borderTop: `1px solid ${theme.grey}`
    }
}

function MenuList({ currentUser, handleLogout, ...props }) {

    const AccordionList = ({name, defaultActiveKey, children,...props}) => {
        return (
          
            <Accordion flush {...props}>
                <Accordion.Item eventKey={defaultActiveKey}>
                    <Accordion.Header className='sublist'>
                        <span className="btn align-items-center rounded">{name}</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        {children}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            
        );
    }

    return (
        <>
            <ul className="list-unstyled ps-0">
                <li className="my-3" style={styles.menuBorder}></li>
                <li className="mb-1">
                    <Link className="btn align-items-center rounded" to={{ pathname: "/"}}>Dashboard</Link>
                </li>
                <li className="mb-1">
                    <Link className="btn align-items-center rounded" to={{ pathname: "/user/" + currentUser.meta._id }}>My Profile</Link>
                </li>
                <AccordionList defaultActiveKey='user' name="Users">
                    <ul className="list-unstyled ps-0">
                        <li className="mb-1 ps-2">
                            <Link className="btn align-items-center rounded" to={{ pathname: "/user/create"}}>Create User</Link>
                        </li>
                        <li className="mb-1 ps-2">
                            <Link className="btn align-items-center rounded" to={{ pathname: "/users"}}>View All Users</Link>
                        </li>
                    </ul>      
                </AccordionList>
                <AccordionList defaultActiveKey="job" name="Jobs">
                    <li className="mb-1">
                        <Link className="btn align-items-center rounded" to={{ pathname: "/job/create"}}>Create Job</Link>
                    </li>
                    <li className="mb-1">
                        <Link className="btn align-items-center rounded" to={{ pathname: "/jobs"}}>View All Jobs</Link>
                    </li>
                </AccordionList>
                <AccordionList defaultActiveKey="quotes" name="Quotes">
                    <li className="mb-1">
                        <Link className="btn align-items-center rounded" to={{ pathname: "/quote/create"}}>Create Quote</Link>
                    </li>
                    <li className="mb-1">
                        <Link className="btn align-items-center rounded" to={{ pathname: "/quotes"}}>View All Quotes</Link>
                    </li>
                </AccordionList>
                <AccordionList defaultActiveKey="asset" name="Assets">
                    <li className="mb-1">
                        <Link className="btn align-items-center rounded" to={{ pathname: "/asset/create"}}>Create Asset</Link>
                    </li>
                    <li className="mb-1">
                        <Link className="btn align-items-center rounded" to={{ pathname: "/assets"}}>View All Assets</Link>
                    </li>
                </AccordionList>
                <AccordionList defaultActiveKey="clients" name="Clients">
                    <li className="mb-1">
                        <Link className="btn align-items-center rounded" to="/client/create">Create Client</Link>
                    </li>
                    <li className="mb-1">
                        <Link className="btn align-items-center rounded" to="/clients">View All Clients</Link>
                    </li>
                </AccordionList>
                <li className="my-3" style={styles.menuBorder}></li>
                <li className="mb-1">
                    <button
                        className="btn align-items-center rounded"
                        onClick={async () => {
                            await handleLogout();
                        }}
                        >
                        Sign Out
                    </button>
                </li>
            </ul>
        </>
    )
}

export default MenuList;