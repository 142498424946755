import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { title } from '../../../helpers/title';
import dateHelper from '../../../helpers/date';
import Topbar from '../../../components/topbar/topbar';
import { Link } from "react-router-dom";
import '../../../views/index.css';
import { Editor } from '@tinymce/tinymce-react';

import { useQuery } from "@apollo/client";
import jobQueries from '../../../queries/job-queries';



function SignOnFormSignOffs({ }) {

    return (
        <>
            <p>Form History</p>
            <Table responsive bordered hover size="sm" >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Completed Time</th>
                        <th>Signature</th>
                    </tr>
                </thead>
                <tbody>
                    <td>Joe Blogs</td>
                    <td>2021/12/01</td>
                    <td><img src="/example-signature.png" height="100" /></td>
                </tbody>
            </Table>
        </>
    );
}

function SignOnFormHistory({ }) {

    return (
        <>
            <p>Form History</p>
            <Table responsive bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Time Exported</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    <td>2021/12/01 09:30</td>
                    <td><a href="/example-form.pdf" >View</a></td>
                </tbody>
            </Table>
        </>
    );
}

function SignOnFormContentEditor({ content }) {

    const editorRef = useRef(null);
    return (
        <>
            <p>Content:</p>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={content}
                apiKey="pzv1lmjqyh4hv6lwvlh617ccuwvsfipkwc7lmwnr95tn1yvh"
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor code | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </>
    );

}

function SignOnFormEntry() {

    var path = window.location.pathname;

    var formId = path.replace('/sign-on/form/', '');

    document.title = title('Sign Ons | Form Entry');

    const exportButton = useRef(null);

    const [disabled, setDisabled] = useState(true);

    const handleEnable = () => setDisabled(false);
    const handleDisable = () => setDisabled(true);

    const { data, loading, error } = useQuery(
        jobQueries.getJobSignOnFormForId,
        {
            variables: {
                "_id": formId
            },
        }
    );

    if (loading) {
        return (<></>)
    }

    const handleSave = (event) => {
        
        handleEnable()

    };

    const handleExport = (event) => {
        
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ 
        //         title: 'React POST Request Example'
        //     })
        // };
        // const response = await fetch('https://reqres.in/api/posts', requestOptions);
        // const data = await response.json();
        // this.setState({ postId: data.id });

    };

    return (
        <div>
            <Topbar title={"Sign Ons | Form Entry"} />

            <div className='page-container'>
                <Col sm={6}>

                    <Form.Group className="mb-3">
                        <Form.Label>Type:</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder={data.jobSignOnFormEntry.type} disabled />
                    </Form.Group>

                    <SignOnFormContentEditor content={data.jobSignOnFormEntry.content} />

                    <div className="button-group mt-4">
                        <Button style={{marginRight: "10px"}} onClick={handleSave} >Save</Button>
                        <Button style={{marginRight: "10px"}} onClick={handleExport} disabled={disabled}>Export</Button>
                    </div>

                    <hr />

                    <SignOnFormSignOffs />

                    <SignOnFormHistory />

                </Col>

            </div>
        </div>
    );
}

export default SignOnFormEntry;