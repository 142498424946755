

// Should Date expires be nullable? given some licence may have not expire date.
export const dummyQualifications = [
    {
        id: '001',
        type: 'Drivers Licence - Manual',
        description: '00001 00001 00001',
        dateValid: '2017/01/19',
        dateExpires: '2022/01/19',
    },
    {
        id: '002',
        type: 'Fork Lift Licence',
        description: '00001 00001 00001',
        dateValid: '2017/01/19',
        dateExpires: '2022/01/19',
    },
    {
        id: '003',
        type: 'White Card',
        description: '00001 00001 00001',
        dateValid: '2017/01/19',
        dateExpires: '2022/01/19',
    }
]