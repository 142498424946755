
const clientUsers = [
    {
        clientId: "001",
        users: [
            {
                name:"Don Jones1",
            },
            {
                name: "Pam Jones1"
            }
        ]
    },
    {
        clientId: "002",
        users: [
            {
                name:"Don Jones2",
            },
            {
                name: "Pam Jones2"
            }
        ]
    },
    {
        clientId: "003",
        users: [
            {
                name:"Don Jones3",
            },
            {
                name: "Pam Jones3"
            }
        ]
    },
    {
        clientId: "004",
        users: [
            {
                name:"Don Jones4",
            },
            {
                name: "Pam Jones4"
            }
        ]
    }
];

export default clientUsers;