import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { title } from '../../../../helpers/title';
import Topbar from '../../../../components/topbar/topbar';
import '../../../../views/index.css';

import '../employees.css';

function UserSignOnBreathTestInfo() {

    // const { jobs, loading } = useJobs();

    const userSubmisson = [
        {
            "user": "Tim John",
            "result": "0.05",
            "comment": "Big Night",
            "uploaded_photo": "/breath-test-placeholder.jpeg",
            "completed_time": "2021/12/02 7:20AM"
        }
    ]


    return userSubmisson.map(({ _id, user, result, comment, uploaded_photo, completed_time }) => (
        <Form>

            <Form.Group className="mb-3">
                <Form.Label>User:</Form.Label>
                <Form.Control id="inlineFormInputName" value={user}  />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Result:</Form.Label>
                <Form.Control id="inlineFormInputName" value={result}  />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Comment:</Form.Label>
                <Form.Control id="inlineFormInputName" value={comment}  />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Completed Time:</Form.Label>
                <Form.Control id="inlineFormInputName" value={completed_time}  />
            </Form.Group>

            <div className="mb-3">
                <p>Photo:</p>
                <img src={uploaded_photo} alt="" style={{ width: "300px" }} />
            </div>

            <Link to={{ pathname: "/employee-sign-on-list"}} className="btn btn-primary submit" rel="noopener noreferrer" >Submit</Link>
            
        </Form>
    ));

}


function UserSignOnBreathTest() {

    document.title = title('Sign Ons | Breath Test');

    return (
        <div>
            {/* <Topbar title={"Sign Ons | User Submisson | Job ${JobId} | Date | ${Type}"} /> */}

            <div className='page-container'>
                <Col sm={6}>

                    <UserSignOnBreathTestInfo />

                </Col>

            </div>
        </div>
    );
}

export default UserSignOnBreathTest;