function SidebarProfileImage({style, currentUser, ...props}) {

    const name = (currentUser.meta.first_name && currentUser.meta.last_name )? currentUser.meta.first_name + " " + currentUser.meta.last_name : "";

    const type = (currentUser.meta.type )? currentUser.meta.type : "";

    const profile_picture = (currentUser.meta.profile_picture )? currentUser.meta.profile_picture : "";

    const roundedLayer = {
        display: "block",
        position: "relative",
        width: "200px",
        height: "200px",
        overflow: "hidden",
        borderRadius: "50%",
        margin: "auto"
    }

    const roundedLayerImage = {
        width: "auto",
        height: "100%"
    }

    return (

        <div style={style}>
            <div className="profile-image" style={{display: "flex" }}>
                <div style={roundedLayer}>
                    <img src={profile_picture} alt="" style={roundedLayerImage} />
                </div>
            </div>

            <div className="profile-name">
                <div style={{ display: "flex", marginTop: "10px" }}>
                    <h2 style={{ margin: "auto" }}>{name}</h2>
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                    <small style={{ margin: "auto" }}>{type}</small>
                </div>

            </div>


        </div>

    );
}

export default SidebarProfileImage;

