
import { useGetJobRestrictionsByJobId } from "./queries/useGetJobRestrictionsByJobIdQuery";

const useJobRestrictionsByJobId = (id) => {
    const { jobRestrictions, loading } = useGetJobRestrictionsByJobId(id);
    return {
        jobRestrictions,
        loading,
    };
};

export default useJobRestrictionsByJobId;