import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';
import '../../views/index.css';
import useUsers from "../../hooks/users/useUsers";
import { Link } from "react-router-dom";

function UserRow({ users }) {



    const roundedLayer = {
        display: "block",
        position: "relative",
        width: "50px",
        height: "50px",
        overflow: "hidden",
        borderRadius: "50%",
        margin: "auto"
    }

    const roundedLayerImage = {
        width: "auto",
        height: "100%"
    }

    if (users.length > 0) {
        return users.map(({ _id, first_name, last_name, email, phone_number, profile_picture }) => (
            <tr>
                <td><div className="profile-image" style={{ display: "flex" }}>
                    <div style={roundedLayer}>
                        <img src={profile_picture} alt="" style={roundedLayerImage} />
                    </div>
                </div></td>
                <td>{first_name + " " + last_name}</td>
                <td><Link to={{ pathname: "mailto:" + email}}>{email}</Link></td>
                <td><Link to={{ pathname: "tel:+" + phone_number}} >{phone_number}</Link></td>
                <td><Link to={{ pathname: `/user/${_id}`}}>View</Link></td>
            </tr>
        ));
    } else {
        return (
            <div style={{ color: 'white', textAlign: 'center' }} >
                <p>No Items found.</p>
            </div>
        )
    }

}



function UserPagination({ users }) {

    const userCount = users.length;


    return (
        <div>
            <span style={{ marginRight: "10px", color: "#0d6efd" }}>{userCount} / {userCount}</span>

            <Link to={{ pathname: "/users?page=1"}} style={{ marginRight: "10px" }}>1</Link>
        </div>
    );


}

function Users(props) {

    document.title = title('Users');

    const { users, loading } = useUsers();

    return (
        <div>
            <Topbar title={"Users"} />
            <div className='page-container'>
                <Col sm={6}>

                    <Form className="mb-5">
                        <Row className="align-items-center">
                            <Col sm={6} className="my-1">
                                <Form.Control id="inlineFormInputName" placeholder=" Search..." />
                            </Col>
                            <Col xs="auto" className="my-1">
                                <Button type="submit">Find</Button>
                            </Col>
                        </Row>
                    </Form>

                    <Table responsive bordered hover size="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Number</th>
                                <th>Edit / View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <UserRow users={users} />
                        </tbody>
                    </Table>

                    <UserPagination users={users} />

                </Col>

            </div>
        </div>
    );
}



export default Users;