import React, {useState, useEffect} from 'react';
import { Modal, Form, Button } from 'react-bootstrap';


const ServiceEditModal = ({modalState, onModalSave, ...props }) => {

    const [modalData, setModalData] = useState({});
    const [error, setFormError] = useState({});

    useEffect(() => {
        setModalData(modalState.data);
    }, [modalState])

    const handleModalCHange = (e) => {
        setModalData({
            ...modalData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <Modal centered size='lg' {...props}>
                <Modal.Header closeButton>
                    {modalState.mode} Asset
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control name='name' value={modalData.name || ''} onChange={handleModalCHange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control name='description' value={modalData.description || ''} onChange={handleModalCHange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Date Due</Form.Label>
                            <Form.Control name='dateDue' value={modalData.dateDue || ''} onChange={handleModalCHange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Avg Km</Form.Label>
                            <Form.Control name='avgKm' value={modalData.avgKm || ''} onChange={handleModalCHange} />
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="primary mt-5" onClick={() => onModalSave(modalData)}>
                                    {modalState.mode === 'Add' ? 'Add': 'Save'}
                            </Button>
                        </Modal.Footer> 
                    </Form>
                    
                </Modal.Body>
            </Modal>
        </div>
    )

}


export default ServiceEditModal;