
import { useGetUserById } from "./queries/useGetUserByIdQuery";

const useUserById = (id) => {
    const { user, loading } = useGetUserById(id);
    return {
        user,
        loading,
    };
};

export default useUserById;