import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GetAllJobsQuery = gql`query {
  jobs {
    _id
		address
		city
		client_id {
      name
    }
		country
		job_code
		manager_id {
      first_name
      last_name
    }
		name
		postcode
		start_date
		state
		status
  }
}`;

export function useAllJobs() {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    GetAllJobsQuery
  );

  const jobs = data?.jobs ?? [];
  return {
    jobs,
    loading,
  };
}