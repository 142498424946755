import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import clientJobs from '../dummies/dummyClientJobs';


const ClientJobsTable = ({ clientId, ...props }) => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
      
        setJobs(clientJobs.filter(client => client.clientId === clientId));
    }, [clientId]);

    return (
        <div>
            <h3 className='my-3'>Jobs: </h3>
            <Table striped bordered hover {...props}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Job Code</th>
                        <th>Start Date</th>
                        <th>Address</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        jobs.map((job, idx) => (
                            <tr key={idx}>
                                <td>{idx}</td>
                                <td>{job.name} </td>
                                <td>{job.jobCode.toUpperCase()}</td>
                                <td>{job.startDate}</td>
                                <td>{job.address}</td>
                                <td><a href={`/job/61de61c7c0357d4026f8597c`}>view</a></td>
                            </tr>
                        ))
                    }
                </tbody>
                
            </Table>
        </div>
    );
}

const areEqual = (prev, next) => {
    return prev.clientId === next.clientId;
}


export default React.memo(ClientJobsTable, areEqual);