

const clientJobs = [
    {
        clientId: "001",
        jobId: "001",
        name: "New Vat",
        jobCode: "DW-job-x-NV-21-11-A",
        startDate: "2021/11/23",
        address: "123 Wine Dr, Kyneton"
    },
    {
        clientId: "002",
        jobId: "002",
        name: "New Vat",
        jobCode: "022551",
        startDate: "2021/11/23",
        address: "123 Wine Dr, Kyneton2"
    },
    {
        clientId: "003",
        jobId: "003",
        name: "New Vat",
        jobCode: "022551",
        startDate: "2021/11/23",
        address: "123 Wine Dr, Kyneton3"
    },
    {
        clientId: "004",
        jobId: "004",
        name: "New Vat",
        jobCode: "022551",
        startDate: "2021/11/23",
        address: "123 Wine Dr, Kyneton4"
    },
    {
        clientId: "001",
        jobId: "005",
        name: "New Vat2",
        jobCode: "DW-job-x-NV-21-11-B",
        startDate: "2021/11/23",
        address: "123 Wine Dr, Kyneton"
    },
];


export default clientJobs;