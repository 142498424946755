import { Button, Form, Col, Table, FormControl } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { title } from '../../helpers/title';
import Topbar from '../../components/topbar/topbar';

import useUserById from "../../hooks/users/useUserById";

import QualificationTable from '../../components/user/QualificationTable';
import { dummyQualifications } from '../../components/dummies/dummyQualifications';
import validators from '../../helpers/validators';
import '../../views/index.css';

const styles = {
    roundedLayer: {
        display: "block",
        position: "relative",
        width: "200px",
        height: "200px",
        overflow: "hidden",
        borderRadius: "50%",
        marginLeft: "50px",
    },
    roundedLayerImage: {
        width: "auto",
        height: "100%"
    }

}

function EdituserProfilePicture(props) {

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();
            }}
        >
            <Form.Group className="mb-3">
                <Form.Label>Profile Photo:</Form.Label>

                <div className="mb-3" style={{ display: "flex" }}>
                    <div style={styles.roundedLayer}>
                        <img src="/example-profile-image.png" alt="" style={styles.roundedLayerImage} />
                    </div>
                </div>
                <Button>
                    Update Photo
                </Button>
            </Form.Group>

        </Form>


    )
}

function EditUserBasicInfo(props) {

    const user = props.user;

    //defaultForm should match the current user, which should be getting from backend
    const defaultForm = {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phone: user.phone_number,
    };

    const [form, setForm] = useState(defaultForm);
    const [formError, setFormError] = useState({});

    const submitButton = useRef(null);

    const [disabled, setDisabled] = useState(true);

    const handleEnable = () => setDisabled(false);
    const handleDisable = () => setDisabled(true);


    const handleSelectChange = (event) => {

        if (event.target.value !== "") {
            handleEnable()
        } else {
            handleDisable()
        }
    };

    const handleFormChange = (name, event) => {
        setForm({
            ...form,
            [name]: event.target.value,
        });
    }

    const findFormErrors = () => {
        const { firstName, lastName, email, phone } = form;
        const newErrors = {};

        //firstName errors, safe destructuring cuz it returns nothing when value is valid
        const { isValid: fNameValid, errorMessage: fNameError } = validators.validateFirstname(firstName);
        if (!fNameValid) { newErrors.firstName = fNameError; }

        //lastName errors
        const { isValid: lNameValid, errorMessage: lNameError } = validators.validateLastName(lastName);
        if (!lNameValid) newErrors.lastName = lNameError;

        //email errors
        const { isValid: emailValid, errorMessage: emailError } = validators.validateEmail(email);
        if (!emailValid) newErrors.email = emailError;

        //phone errors
        const { isValid: pValid, errorMessage: phoneError } = validators.validatePhone(phone);
        if (!pValid) newErrors.phone = phoneError;

        return newErrors;

    }

    const onFormSubmit = () => {
        const errors = findFormErrors();
        if (Object.keys(errors).length > 0) {
            setFormError(errors);
            return;
        }

        console.log('success');

    }

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();
                onFormSubmit();
            }}
        >

            <Form.Group className="mb-3">
                <Form.Label>First Name: </Form.Label>
                <Form.Control id="inlineFormInputName" placeholder="Jane" name='firstName' value={form.firstName} onChange={e => handleFormChange('firstName', e)} isInvalid={!!formError.firstName} />
                <FormControl.Feedback type='invalid'>
                    {formError.firstName}
                </FormControl.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Last Name: </Form.Label>
                <Form.Control id="inlineFormInputName" placeholder="Doe" name='lastName' value={form.lastName} onChange={e => handleFormChange('lastName', e)} isInvalid={!!formError.lastName} />
                <FormControl.Feedback type='invalid'>
                    {formError.lastName}
                </FormControl.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Email Address: </Form.Label>
                <Form.Control id="inlineFormInputName" placeholder="user@example.com" name="email" value={form.email} onChange={e => handleFormChange('email', e)} isInvalid={!!formError.email} />
                <FormControl.Feedback type='invalid'>
                    {formError.email}
                </FormControl.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Phone Number: </Form.Label>
                <Form.Control id="inlineFormInputName" placeholder="0401 001 001" name="phone" value={form.phone} onChange={e => handleFormChange('phone', e)} isInvalid={!!formError.phone} />
                <FormControl.Feedback type='invalid'>
                    {formError.phone}
                </FormControl.Feedback>
            </Form.Group>

            <Button type="submit" variant="primary mt-5">
                Save
            </Button>
        </Form>
    )
}

function EditUser(props) {

    document.title = title('Edit User | $UserId');

    var path = window.location.pathname;

    var userId = path.replace('/user/edit/', '');

    const { user, loading } = useUserById(userId);

    const [tableData, setTableData] = useState(dummyQualifications);

    const handleTableChange = (list) => {
        setTableData(list);
    }

    return (
        <div>
            <Topbar title={"Edit User"} />
            <div className='page-container'>
                <Col sm={6}>

                    <EdituserProfilePicture user={user}  />

                    <hr style={{ marginTop: "40px" }} />

                    <EditUserBasicInfo user={user}  />

                    <hr style={{ marginTop: "40px" }} />

                    <QualificationTable user={user} qualifications={tableData} onTableChange={handleTableChange} />

                </Col>

            </div>
        </div>
    );
}



export default EditUser;