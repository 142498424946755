
import { useGetUserByAppUserId } from "./queries/useGetUserByAppUserIdQuery";

const useUserByAppUserId = (id) => {
    const { user, loading } = useGetUserByAppUserId(id);
    return {
        user,
        loading,
    };
};

export default useUserByAppUserId;