import React, { useState } from 'react';
import { title } from '../../helpers/title';
import { ButtonGroup, Dropdown, ToggleButton } from 'react-bootstrap';
import Map from '../../components/map/map';
import { Form, Row, Col, Button } from 'react-bootstrap';
import '../index.css'
import IconRoute from '../../components/common/icon';
import theme from '../../themes/theme';
const styles = {
    centerText: {
        margin: "auto 100px auto auto"
    },
    searchBar: {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
    },
    searchButton: {
        margin: "auto 5px",
        color: 'black'
    },
    buttonActive: {
        background: '#95a4af',
        color: 'black',
    },
    buttonInActive: {
        background: 'white',
        color: 'black',
    },
    topBar: {
        height: "8vh",
        width: "100%",
        display: "flex",
        borderBottom: `1px solid ${theme.grey}`
    }

}


const Filter = ({ props }) => {

    return (
        <div style={styles.searchBar}>
            <Form style={{ margin: 'auto' }}>
                <Row className="align-items-center" style={{ display: "flex" }}>
                    <Col sm={6} className="my-1">
                        <Form.Control id="inlineFormInputName" placeholder={`Search`} />
                    </Col>
                    <Col sm={6} className="my-1">
                        <div style={{ display: "flex" }}>
                            <Button type="button" variant="outline-secondary" style={{marginRight: "10px"}}>Jobs</Button>
                            <Button type="button" variant="secondary" style={{marginRight: "10px"}}>Users</Button>
                            <Button type="button" variant="outline-secondary" style={{marginRight: "10px"}}>Assets</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div >
    )

}


function Topbar() {
    
    return (
        <div style={styles.topBar}>
            <div style={{ margin: "auto 0 auto 50px" }}>
                <h1>Dashboard | Map</h1>
            </div>
            <Filter />
            <div style={styles.centerText}>
                <IconRoute route="/calendar" icon={require('../../images/calendar.png')} />
            </div>
        </div>
    );
}


function DashboardMap() {

    document.title = title('Dashboard | Map');

    return (
        <div style={{ height: "100%" }}>
            <Topbar />
            <Map />
        </div>
    );
}



export default DashboardMap;