import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { title } from '../../../helpers/title';
import dateHelper from '../../../helpers/date';
import Topbar from '../../../components/topbar/topbar';
import '../../../views/index.css';
import { Link } from "react-router-dom";

import { isReference, useQuery } from "@apollo/client";
import jobQueries from '../../../queries/job-queries';

function SignOnBreathTestEntries({ entries }) {

    return entries.map(({ _id, user_id, complete_time, image_url }) => (
        <tr>
            <td>{user_id.first_name + " " + user_id.last_name}</td>
            <td>{dateHelper.timestampToDateTimeString(parseInt(complete_time)) }</td>
            <td><img src={image_url} alt="" srcset="" height="100px" /></td>
            <td><Link to={{ pathname: `/sign-on/breath-test/${_id}`}}>View</Link></td>
        </tr>
    ));

}


function SignOnDailyBreathTestEntries() {

    var path = window.location.pathname;

    var signOnEntryId = path.replace('/sign-on/breath-tests/', '');

    document.title = title('Sign Ons | Daily Submissons');

    const { data, loading, error } = useQuery(
        jobQueries.getJobSignOnBreathTestsForJobSigOnEntryId,
        {
            variables: {
                "JobSignOnEntry": {
                    "_id": signOnEntryId
                }
            },
        }
    );

    if (loading) {
        return (<></>)
    }


    return (
        <div>
            <Topbar title={"Sign Ons | Daily Submissons | Job ${JobId} | Date | ${Type}"} />

            <div className='page-container'>
                <Col sm={6}>

                    <Table responsive bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Completed Time</th>
                                <th>Uploaded Photo</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <SignOnBreathTestEntries entries={data.jobSignOnBreathTestEntries} />
                        </tbody>
                    </Table>
                </Col>

            </div>
        </div>
    );
}

export default SignOnDailyBreathTestEntries;