import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GetJobByIdQuery = gql`
query GetJobById($_id: ObjectId!) {
    job(query: { _id: $_id }) {
      _id
      address
      city
      client_id {
        name
      }
      country
      job_code
      manager_id {
        first_name
        last_name
      }
      name
      postcode
      start_date
      state
      status
    }
}
`;

export function useGetJobById(id) {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    GetJobByIdQuery,
    {
      variables: {
        _id: id,
      },
    }
  );

  const job = data?.job ?? {};
  return {
    job,
    loading,
  };
}