import { Button, Form, Col, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { title } from '../../../helpers/title';
import dateHelper from '../../../helpers/date';
import Topbar from '../../../components/topbar/topbar';
import '../../../views/index.css';
import './employees.css';


function EmployeeSignOnList(props) {

    // var path = window.location.pathname;

    // const { user, loading } = useUserById(userId);

    // document.title = title(`User | ${user.first_name} ${user.last_name}`);


    return (
        <div>
            <div className='page-container'>
                <Col sm={6}>

                    <h3>Daily Sign On</h3>

                    <Link to={{ pathname: "/employee-sign-on-breath-test"}} className="btn btn-primary signon mb-3" rel="noopener noreferrer">Breath Test</Link>
                    <Link to={{ pathname: "/employee-sign-on-list"}} className="btn btn-primary signon mb-3" rel="noopener noreferrer">Medication</Link>
                    <Link to={{ pathname: "/employee-sign-on-list"}} className="btn btn-primary signon mb-3" rel="noopener noreferrer">Packing List</Link>
                    <Link to={{ pathname: "/employee-sign-on-list"}} className="btn btn-primary signon mb-3" rel="noopener noreferrer">Tool Box Meeting</Link>
                    <Link to={{ pathname: "/employee-sign-on-list"}} className="btn btn-primary signon mb-3" rel="noopener noreferrer">Safe Work Method Statement</Link>
                    <Link to={{ pathname: "/employee-sign-on-list"}} className="btn btn-primary signon mb-3" rel="noopener noreferrer">JSA's</Link>
                    <Link to={{ pathname: "/employee-sign-on-list"}} className="btn btn-primary signon mb-3" rel="noopener noreferrer">Risk Assessment</Link>

                    <div className="complete-container">
                    <Link to={{ pathname: "/employee-sign-on-list"}} className="btn btn-primary complete" rel="noopener noreferrer">Complete Sign On</Link>
                    </div>

                </Col>
            </div>
        </div>
    );
}



export default EmployeeSignOnList;