import { Button, Form, Col, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { title } from '../../helpers/title';
import dateHelper from '../../helpers/date';
import Topbar from '../../components/topbar/topbar';
import '../../views/index.css';
import { Link } from "react-router-dom";


import useUserById from "../../hooks/users/useUserById";

import useUserQualificationByUserId from "../../hooks/users/useUserQualificationByUserId";

function UserBasicInfo(props) {

    const user = props.user;

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>First Name: </Form.Label>
                <Form.Control id="inlineFormInputName" placeholder={user.first_name} disabled />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Last Name: </Form.Label>
                <Form.Control id="inlineFormInputName" placeholder={user.last_name} disabled />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Email Address: </Form.Label>
                <Form.Control id="inlineFormInputName" placeholder={user.email} disabled />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Phone Number: </Form.Label>
                <Form.Control id="inlineFormInputName" placeholder={user.phone_number} disabled />
            </Form.Group>
        </>

    )
}

function UserProfilePicture() {

    const roundedLayer = {
        display: "block",
        position: "relative",
        width: "200px",
        height: "200px",
        overflow: "hidden",
        borderRadius: "50%",
        marginLeft: "50px"
    }

    const roundedLayerImage = {
        width: "auto",
        height: "100%"
    }

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>Profile Photo:</Form.Label>

                <div className="mb-3" style={{ display: "flex" }}>
                    <div style={roundedLayer}>
                        <img src="/example-profile-image.png" alt="" style={roundedLayerImage} />
                    </div>
                </div>
            </Form.Group>
        </>
    )
}

function UserQualifications(props) {

    const user = props.user;
    const { userQualifications, loading } = useUserQualificationByUserId(user.id);

    return (
        <>
            <p>Qualifications: </p>
            <Table responsive bordered hover size="sm" className='table-responsive'>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Number</th>
                        <th>Description</th>
                        <th>Date Valid</th>
                        <th>Date Expires</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        userQualifications.map(({ name, number, description, date_valid, date_expires }) => {
                            return (
                                <tr>
                                    <td>{name}</td>
                                    <td>{number}</td>
                                    <td>{description}</td>
                                    <td>{ dateHelper.timestampToDateString(date_valid)}</td>
                                    <td>{dateHelper.timestampToDateString(date_expires)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </>
    )
}


function User(props) {

    var path = window.location.pathname;

    var userId = path.replace('/user/', '');

    const { user, loading } = useUserById(userId);

    document.title = title(`User | ${user.first_name} ${user.last_name}`);

    const [disabled, setDisabled] = useState(true);

    const handleEnable = () => setDisabled(false);
    const handleDisable = () => setDisabled(true);


    return (
        <div>
            <Topbar title={"User"} />
            <div className='page-container'>
                <Col sm={6}>
                    <Form>

                        <UserProfilePicture profilePicture={user.profile_picture}/>

                        <UserBasicInfo user={user} />

                        <UserQualifications user={user} />

                        <Link to={{ pathname: "/user/edit/" + userId}} className="btn btn-primary">
                            Edit
                        </Link>
                    </Form>
                </Col>

            </div>
        </div>
    );
}



export default User;