import React from 'react';
import { Form } from 'react-bootstrap';

const CustomFormSelect = ({ name, options, ...props }) => {
    return (
        <>
            <Form.Control as="select" className='form-select' name={name} {...props}>
                <option disabled value=''>Select {name}</option>
                {
                    options.map((option, idx) => (
                        <option value={option} key={idx}>{ option }</option>
                    ))
                }
            </Form.Control>
        </>
    )
}


export default CustomFormSelect;
