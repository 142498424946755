import React from 'react'

const Hamburger = ({onClick, style, ...props}) => {

    const styles = {
        hamburger: {
            width: 'auto',
            alignItems:'center',
            height: '2rem',
            display: 'flex',
            justifyContent: 'space-around',
            flexFlow: 'column nowrap',
        },
        burger: {
            width: '2rem',
            height: '0.25rem',
            borderRadius: '10px',
            backgroundColor: 'black',
            transformOrigin: '1px',
            transition: 'all 0.3s linear'
        }
    }

    return (
        <>
            <div style={{...style, ...styles.hamburger}} onClick={onClick} {...props}>
                <div style={styles.burger}></div>
                <div style={styles.burger}></div>
                <div style={styles.burger}></div>
            </div>
        </>
        
    );

}

export default Hamburger;