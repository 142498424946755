import { Button, Form, Col, Row, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { title } from '../../../helpers/title';
import Topbar from '../../../components/topbar/topbar';
import '../../../views/index.css';
import { useQuery } from "@apollo/client";
import jobQueries from '../../../queries/job-queries';
import { Link } from "react-router-dom";

import dateHelper from '../../../helpers/date';

function SignOnBreathTest({ signOnId }) {

    const { data, loading, error } = useQuery(
        jobQueries.getJobSignOnBreathTestsForJobSigOnEntryId,
        {
            variables: {
                "JobSignOnEntry": {
                    "_id": signOnId
                }
            },
        }
    );

    if (loading) {
        return (<></>)
    }

    const totalBreathTest = data.jobSignOnBreathTestEntries.length;

    const completeBreathTests = data.jobSignOnBreathTestEntries.filter(entry => entry.complete_time != null).sort((a, b) => { return a.complete_time - b.complete_time });

    const totalCompletedBreathTest = completeBreathTests.length;

    const lastestCompleteTime = (totalCompletedBreathTest > 0) ? completeBreathTests[totalCompletedBreathTest - 1].complete_time : "n/a";

    return (
        <tr>
            <td>Breath Test</td>
            <td>{totalCompletedBreathTest + "/" + totalBreathTest}</td>
            <td>{dateHelper.timestampToDateTimeString(parseInt(lastestCompleteTime))}</td>
            <td><Link to={{ pathname: `/sign-on/breath-tests/${signOnId}` }}>View</Link></td>
        </tr>
    );

}

function SignOnExampleForm({ signOnId }) {

    const { data, loading, error } = useQuery(
        jobQueries.getJobSignOnFormEntryForJobSigOnEntryId,
        {
            variables: {
                "JobSignOnEntry": {
                    "_id": signOnId
                },
                "type": "Example Form"
            },
        }
    );

    if (loading) {
        return (<></>)
    }

    const totalExampleForms = data.jobSignOnFormEntries.length;

    // const completeExampleForms = data.jobSignOnFormEntries.filter(entry => entry.complete_time != null).sort((a, b) => { return a.complete_time - b.complete_time});

    // const totalCompletedBreathTest = completeBreathTests.length;

    // const lastestCompleteTime = (totalCompletedBreathTest > 0)? completeBreathTests[totalCompletedBreathTest - 1].complete_time : "n/a";

    return data.jobSignOnFormEntries.map(({_id, type}) => (
        <tr>
            <td>{type}</td>
            <td>-</td>
            <td>-</td>
            <td><Link to={{ pathname: `/sign-on/form/${_id}`}}>View</Link></td>
        </tr>
    ));

}

// function SignOnRow({signOnId}) {

//     const signOns = [
//         {
//             "name" : "Breath Test",
//             "completed_status" : "10 / 10",
//             "last_update" : "2021/12/02 7:20AM",
//             "_id" : "1"
//         },
//         {
//             "name" : "Medication",
//             "completed_status" : "10 / 10",
//             "last_update" : "2021/12/02 7:20AM",
//             "_id" : "2"
//         },
//         {
//             "name" : "Packing List",
//             "completed_status" : "10 / 10",
//             "last_update" : "2021/12/02 7:20AM",
//             "_id" : "3"
//         },
//         {
//             "name" : "Tool Box Meeting",
//             "completed_status" : "10 / 10",
//             "last_update" : "2021/12/02 7:20AM",
//             "_id" : "4"
//         },
//         {
//             "name" : "Safe Work Method Statement",
//             "completed_status" : "10 / 10",
//             "last_update" : "2021/12/02 7:20AM",
//             "_id" : "5"
//         },
//         {
//             "name" : "JSA's",
//             "completed_status" : "10 / 10",
//             "last_update" : "2021/12/02 7:20AM",
//             "_id" : "6"
//         },
//         {
//             "name" : "Risk Assessment",
//             "completed_status" : "10 / 10",
//             "last_update" : "2021/12/02 7:20AM",
//             "_id" : "7"
//         },
//         {
//             "name" : "HSE",
//             "completed_status" : "10 / 10",
//             "last_update" : "2021/12/02 7:20AM",
//             "_id" : "8"
//         }
//     ];

//     if (signOns.length > 0) {
//         return signOns.map(({ _id, name, completed_status, last_update }) => (
//             <tr>
//                 <td>{name}</td>
//                 <td>{completed_status}</td>
//                 <td>{last_update}</td>
//                 <td><Link to={{ pathname: `/job/sign-on/daily-submissions/${_id}`}}>View</Link></td>
//             </tr>
//         ));
//     } else {
//         return (
//             <div style={{ color: 'white', textAlign: 'center' }} >
//                 <p>No Items found.</p>
//             </div>
//         )
//     }

// }


function SignOns(props) {

    document.title = title('Sign Ons | ${jobId}');

    var path = window.location.pathname;

    var signOnId = path.replace('/sign-on/', '');

    return (
        <div>
            <Topbar title={"Sign Ons | ${jobId}"} />

            <div className='page-container'>
                <Col sm={6}>

                    <Table responsive bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Completed Status</th>
                                <th>Last Update</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <SignOnBreathTest signOnId={signOnId} />
                            <SignOnExampleForm signOnId={signOnId} />
                        </tbody>
                    </Table>
                </Col>

            </div>
        </div>
    );
}

export default SignOns;