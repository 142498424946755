
import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import RequiredSignOnModal from '../modal/SignOnRequirementsModal';

const SignOnRequirements = ({ qualifications }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('');

    const handleEdit = (id) => {
        setModalOpen(true);
        setModalMode({ mode: 'Edit', id: id })
    }

    const addRequiredSignOn = () => {

        setModalOpen(true);
        setModalMode({ mode: 'Add' });
    }

    return (
        <div className="mb-3">
            <p>Sign On Requirements: </p>
            <Table responsive bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Sign On Requirements</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2021/12/01</td>
                        <td>
                            <ul>
                                <li>Breath Test</li>
                                <li>Medication</li>
                                <li>Packing List</li>
                                <li>Safe Work Method Statement</li>
                                <li>JSA's</li>
                                <li>Risk Assessment</li>
                                <li>HSE</li>
                            </ul>
                        </td>
                        <th><Button onClick={() => handleEdit('001')}>Edit</Button></th>
                    </tr>
                </tbody>
            </Table>

            <Button variant='primary' onClick={addRequiredSignOn}>
                Add
            </Button>

            <RequiredSignOnModal
                show={modalOpen}
                modalMode={modalMode}
                onHide={() => setModalOpen(false)}
            />
        </div>

    )

}

export default SignOnRequirements;