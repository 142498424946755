

const clientJobs = [
    {
        clientId: "001",
        jobId: "001",
        name: "New Vat",
        jobCode: "DW-quo-x-NV-21-11-A",
        startDate: "2021/11/23",
        address: "123 Wine Dr, Kyneton"
    },
    {
        clientId: "001",
        jobId: "005",
        name: "New Vat2",
        jobCode: "DW-quo-x-NV-21-11-B",
        startDate: "2021/11/23",
        address: "123 Wine Dr, Kyneton"
    },
];


export default clientJobs;