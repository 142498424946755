import React, { useState, useEffect, useRef } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ClientJobsTable from '../../components/clients/client-jobs-table';
import ClientUsersTable from '../../components/clients/client-user-table';
import clients from '../../components/dummies/dummyClients';
import Topbar from '../../components/topbar/topbar';
import validate from '../../helpers/formValidators/clientFormValidator';
import COUNTRY_STATE from '../../components/dummies/dummyCountryState';
import CustomFormSelect from '../../components/clients/customFormSelect';
import QuotesTable from '../../components/clients/quotes-table';

const Client = () => {
    console.log('rd')
    const { clientId } = useParams();
    const [clientData, setClientData] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [formError, setFormError] = useState({});
    const clientName = useRef('');

    useEffect(() => {
        const client = clients.find(client => {
            return client.id === clientId;
        })
        clientName.current = client.name;
        setClientData(client);
    }, [clientId]);
    
    const handleFieldChange = e => {
        setClientData({
            ...clientData,
            [e.target.name]: e.target.value
        })
    }

    const handleSaveButton = (e) => {
        if (!isEditing) {
            setIsEditing(true)
            return;
        }
        
        if (isEditing) {
            e.preventDefault();
            const errors = validate(clientData);

            if (Object.keys(errors).length > 0) {
                setFormError(errors);
                return;
            }
            
            //no form error, send data through api
            setFormError({});
            setIsEditing(false);
            alert(JSON.stringify(clientData));
        }
        
    }

    return (
        <div>
            <Topbar title={`Client | ${clientName.current} `} />

            <div className='page-container'>
                <h3 className='mb-3'>Client:</h3>
                <Col sm={6}>
                    <Form>
                        <Form.Group className='mb-3'>
                            <Form.Label>Name:</Form.Label>
                            <Form.Control name="name" value={clientData.name || ''} isInvalid={!!formError.name} disabled={!isEditing} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{ formError.name }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>ABN:</Form.Label>
                            <Form.Control name="abn" value={clientData.abn || ''} isInvalid={!!formError.abn}  disabled={!isEditing} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{ formError.abn }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>Email Address:</Form.Label>
                            <Form.Control name="email" value={clientData.email || ''} isInvalid={!!formError.email} disabled={!isEditing} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{ formError.email }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>Phone Number:</Form.Label>
                            <Form.Control name="phone" value={clientData.phone || ''} isInvalid={!!formError.phone}  disabled={!isEditing} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{ formError.phone }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>Address:</Form.Label>
                            <Form.Control name="address" value={clientData.address || ''} isInvalid={!!formError.address}  disabled={ !isEditing } onChange={handleFieldChange}/>
                            <Form.Control.Feedback type='invalid'>{ formError.address }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>City:</Form.Label>
                            <Form.Control name="city" value={clientData.city || ''} isInvalid={!!formError.city}  disabled={!isEditing} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{ formError.city }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>{ clientData.country }</Form.Label>
                            <CustomFormSelect name="state" isInvalid={!!formError.state} value={clientData.state || ''} disabled={!isEditing} options={COUNTRY_STATE[clientData.country || 'Australia']} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{formError.state}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>Postcode:</Form.Label>
                            <Form.Control name="postcode" value={clientData.postcode || ''} isInvalid={!!formError.postcode}  disabled={!isEditing} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{ formError.postcode }</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Country</Form.Label>
                            <CustomFormSelect name="country" isInvalid={!!formError.country} disabled={!isEditing} value={clientData.country || ''} options={Object.keys(COUNTRY_STATE)} onChange={handleFieldChange} />
                            <Form.Control.Feedback type='invalid'>{formError.country}</Form.Control.Feedback>
                        </Form.Group>

                        <Button onClick={handleSaveButton}>
                            {!isEditing ? 'Edit' : 'Save'}
                        </Button>
                    </Form>
                    
                    <ClientUsersTable clientId={clientId} />

                    <ClientJobsTable clientId={clientId} />
                    
                    <QuotesTable clientId={clientId} />
                </Col>
            </div>
            
            
        </div>
    )
}


export default Client;