import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SidebarSignOn from "../sidebar/sidebar-sign-on";

import { EmployeeWelcome, EmployeeSignOnList, EmployeeSignOnBreathTest } from "../../views";

import { useMediaQuery } from "react-responsive";
import OffCanvasSidebarSignOn from '../sidebar/off-canvas-sidebar-sign-on';

function SignOnLayout() {
  const tabletSize = useMediaQuery({ query: '(max-width: 768px)' });

  const styles = {
    container: {
      display: 'flex',
      flexDirection: tabletSize ? 'column' : 'row',
    },
    sidebar: {
      width: tabletSize ? "100%" : "350px"
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.sidebar}>
        {tabletSize ? <OffCanvasSidebarSignOn /> : <SidebarSignOn />}
      </div>

      <div style={{ width: "100%", height: "100vh" }}>
        <Router>
          <Routes>

            <Route path="/" element={<EmployeeWelcome />} />
            <Route path="/employee-sign-on-list" element={<EmployeeSignOnList />} />

            <Route path="/employee-sign-on-breath-test" element={<EmployeeSignOnBreathTest />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}



export default SignOnLayout;