import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

function Calendar() {

    const events = [
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-10T08:30:00',
            start: '2022-01-10T17:30:00',
        },
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-11T08:30:00',
            start: '2022-01-11T17:30:00',
        },
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-12T08:30:00',
            start: '2022-01-12T17:30:00',
        },
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-13T08:30:00',
            start: '2022-01-13T17:30:00',
        },
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-14T08:30:00',
            start: '2022-01-14T17:30:00',
        },
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-17T08:30:00',
            start: '2022-01-17T17:30:00',
        },
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-18T08:30:00',
            start: '2022-01-18T17:30:00',
        },
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-19T08:30:00',
            start: '2022-01-19T17:30:00',
        },
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-20T08:30:00',
            start: '2022-01-20T17:30:00',
        },
        {
            title: 'Dominic - TWA App Build',
            start: '2022-01-21T08:30:00',
            start: '2022-01-21T17:30:00',
        },
        {
            title: 'Dominic - TWA Demo',
            start: '2022-01-21T10:00:00',
            end: '2022-01-21T11:30:00',
            description: 'Client Meeting',
            backgroundColor: "red",
            display: 'block'
        }
    ]


    return (
        <FullCalendar
            height="100%"
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={
                events
            }
        />
    );
}


export default Calendar;