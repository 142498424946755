import React from 'react';
import { Link } from "react-router-dom";

const IconRoute = ({ icon, route, ...props }) => {

    return (
        <div style={{ margin: "auto 100px auto auto" }}>
            <Link to={{ pathname: route}}>
                <img style={{ height: '30px' }} src={icon} alt={ route !== '/' ? `to ${route}` : 'to main page' }/>
            </Link>
        </div>
    )
}

export default IconRoute;