import theme from "../../themes/theme";

const styles = {
    topBar: {
        height: "8vh",
        width: "100%",
        display: "flex",
        borderBottom: `1px solid ${theme.grey}`
    }
}


function Topbar(props) {

    const title = (props.title)? props.title : "Dashboard"; 

    return (
        <div style={styles.topBar}>
            <div style={{ margin: "auto 0 auto 50px" }}>
                <h1>{title}</h1>
            </div>
        </div>
    );
}

export default Topbar;

