import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GetAllUsersQuery = gql`
  query {
    users {
      _id
      app_user_id
      email
      first_name
      last_name
      phone_number
      type
      profile_picture
    }
  }
`;

export function useAllUsers() {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    GetAllUsersQuery
  );


  const users = data?.users ?? [];
  return {
    users,
    loading,
  };
}