

const dateHelper = {

    addZero: function (i) {
        if (i < 10) { i = "0" + i }
        return i;
    },

    timestampToDateString: function (timestamp = "") {

        // YYYY/MM/DD

        if (timestamp.length == 10) {
            timestamp = timestamp * 1000; //js uses milliseconds not seconds
        }

        var inputDate = new Date(timestamp);

        var inputYear = inputDate.getFullYear();
        var inputMonth = this.addZero(inputDate.getMonth() + 1);
        var inputDay = this.addZero(inputDate.getDate());

        return `${inputYear}/${inputMonth}/${inputDay}`;
    },

    timestampToDateTimeString: function (timestamp = "") {

        // YYYY/MM/DD HH:MM

        if (timestamp.length == 10) {
            timestamp = timestamp * 1000; //js uses milliseconds not seconds
        }

        var inputDate = new Date(timestamp);

        var inputYear = inputDate.getFullYear();
        var inputMonth = this.addZero(inputDate.getMonth() + 1);
        var inputDay = this.addZero(inputDate.getDate());

        var inputHour = this.addZero(inputDate.getHours());
        var inputMinute = this.addZero(inputDate.getMinutes());

        return `${inputYear}/${inputMonth}/${inputDay} ${inputHour}:${inputMinute}`;
    },

};

export default dateHelper;
