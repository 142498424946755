
import { useGetUserQualificationByUserId } from "./queries/useGetUserQualificationByUserIdQuery";

const useUserQualificationByUserId = (id) => {
    const { userQualifications, loading } = useGetUserQualificationByUserId(id);
    return {
        userQualifications,
        loading,
    };
};

export default useUserQualificationByUserId;