import { Button, Form, Col, Table } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { title } from '../../../helpers/title';
import dateHelper from '../../../helpers/date';
import Topbar from '../../../components/topbar/topbar';
import '../../../views/index.css';
import './employees.css';


function EmployeeWelcome(props) {

    // var path = window.location.pathname;

    // const { user, loading } = useUserById(userId);

    // document.title = title(`User | ${user.first_name} ${user.last_name}`);


    return (
        <div>
            <div className='page-container'>
                <Col sm={6}>
                    <div className="content-container">
                        <h2 className="mb-5">Welcome: Joe Blogs</h2>
                        <p className="mb-5">You have 7/7 sign on steps to complete for job "kew-job-p-nv-21-12-c" before continuing</p>
                        <Link to={{ pathname: "/employee-sign-on-list"}} className="btn btn-primary signon" rel="noopener noreferrer">Sign On</Link>
                    </div>
                </Col>
            </div>
        </div>
    );
}



export default EmployeeWelcome;