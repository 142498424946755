
const validateAssetForm = (assetForm) => {
    const errors = {};
    const { name, make, model, odometer, rego, year } = assetForm;
        
    if (!name || name === '') errors.name = 'Name cannot be empty';
    if (!make || make === '') errors.make = 'Make cannot be empty';
    if (!model || model === '') errors.model = 'Model cannot be empty';
    if (!odometer || !parseInt(odometer)) errors.odometer = 'Odometer reading error';
    if (!rego || rego === '') errors.rego = 'Rego cannot be empty';
    if (!year || year === '') errors.year = 'Year cannot be empty';

    return errors;

}


export default validateAssetForm;