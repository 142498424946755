
import { useGetJobById } from "./queries/useGetJobQuery";

const useJobById = (id) => {
    const { job, loading } = useGetJobById(id);
    return {
        job,
        loading,
    };
};

export default useJobById;