import React, { useCallback, useEffect, useState } from 'react';
import { Table, Button, FormControl } from 'react-bootstrap';
import clientUsers from '../dummies/dummyClientUsers';
import ClientUserModal from '../modal/client-user-modal';
const findClientUsers = (id) => {
    return clientUsers.find(client => {
        return client.clientId === id;
    })
}

const ClientUsersTable = ({ clientId, ...props }) => {

    const defaultModalState = {
        mode: 'Add',
        data:{}
    }
    const [clientUsers, setClientUsers] = useState([]);
    const [isEditingTable, setIsEditingTable] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalState, setModalState] = useState(defaultModalState);


    useEffect(() =>
        {
            const clientUsers = findClientUsers(clientId).users;
            setClientUsers(clientUsers);
        }
    ,[clientId]);
    
    const handleEditing = () => {
        setIsEditingTable(!isEditingTable);
    }

    const handleRowEdit = useCallback((e, idx) => {
        const rowData = clientUsers[idx];
        setModalState({
            mode: 'Edit',
            index: idx,
            data: rowData
        })
        setModalOpen(true);
    },[clientUsers])

    const handleRowDelete = (e, itemIndex) => {
        
        if (window.confirm('Are you sure to delete it?')) {
            const newList = clientUsers.filter((_, idx) => {
                return idx !== itemIndex;
            });
            setClientUsers(newList);
        }
        

    }

    const handleRowAdd = () => {
        const modalState = {
            mode: 'Add',
            data: {}
        };

        setModalState(modalState);
        setModalOpen(true);
    }

    const handleModalOnSave = (modalData) => {
        const modalMode = modalState.mode;

        if (modalMode === 'Add') {
            setClientUsers([...clientUsers, modalData]);
            
        }
        if (modalMode === 'Edit') {
            const index = modalState.index;
            const newData = modalData;

            if (clientUsers.length !== 0) {
                const newList = clientUsers;
                newList[index] = newData;

                setClientUsers(newList);
            }
        }

        setModalOpen(false);
    }



    console.log('rendering ctable');
    return (
        <div style={{ marginTop: '30px' }}>
            <div style={{paddingBottom:'30px'}}>
                <h3>Employees: </h3>
            </div>
            <div>
                <Table responsive bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            {isEditingTable &&
                                (   
                                    <>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {clientUsers.map((user,idx) => {
                            return (
                                <tr key={idx}>
                                    <td value={user.name}>{user.name}</td>
                                    {isEditingTable &&
                                        (
                                        <>
                                            <td><Button onClick={(e) => handleRowEdit(e ,idx)} style={{ float: 'right' }}>Edit</Button></td>
                                            <td><Button onClick={(e) => handleRowDelete(e, idx)} style={{ float: 'right' }}>Delete</Button></td>
                                        </>
                                        
                                    )
                                    }
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>
                {
                    isEditingTable &&  (<Button onClick={handleRowAdd} style={{float:'right', marginTop:'10px'}}> Add </Button>)
                }
            </div>
            <Button onClick={handleEditing}>
                {!isEditingTable ? 'Edit' : 'Save'}
            </Button>

            <ClientUserModal
                show={modalOpen}
                onHide={() => setModalOpen(false)}
                modalState={modalState}
                onModalSave={handleModalOnSave}
            />
        </div>
    )
}

const areEqual = (prev, next) => {
    return prev.clientId === next.clientId;
}

export default React.memo(ClientUsersTable, areEqual);