import validators from "../validators";

const validate = (formData) => {
    const errors = {};
    const { name, abn, email, phone, address, city, state, postcode, country } = formData;

    const { isValid: nameValid, errorMessage: nameError } = validators.validateGenericString(name);
    if (!nameValid) errors.name = nameError;

    const { isValid: abnValid, errorMessage: abnError } = validators.validateAbn(abn);
    if (!abnValid) errors.abn = abnError;

    const { isValid: emailValid, errorMessage: emailError } = validators.validateEmail(email);
    if (!emailValid) errors.email = emailError;

    const { isValid: phoneValid, errorMessage: phoneError } = validators.validatePhone(phone);
    if (!phoneValid) errors.phone = phoneError;

    const { isValid: addressValid, errorMessage: addrError } = validators.validateGenericString(address);
    if (!addressValid) errors.address = addrError;

    const { isValid: cityValid, errorMessage: cityError } = validators.validateGenericString(city);
    if (!cityValid) errors.city = cityError;

    const { isValid: stateValid, errorMessage: stateError } = validators.validateGenericString(state);
    if (!stateValid) errors.state = stateError;

    const { isValid: postCodeValid, errorMessage: postcodeError } = validators.validatePostcode(postcode);
    if (!postCodeValid) errors.postcode = postcodeError;

    const { isValid: countryValid, errorMessage: countryError } = validators.validateGenericString(country);
    if (!countryValid) errors.country = countryError;

    return errors;
}

export default validate;