import React, {useState} from "react";
import { useRealmApp } from "../../providers/realm/realmApp";
import { Offcanvas } from "react-bootstrap";
import Hamburger from "./Hamburger";
import SidebarProfileImage from "./sidebar-profile-image";
import MenuList from "./menu-list";
import useUserByAppUserId from "../../hooks/users/useUserByAppUserId";
const styles = {
    container: {
        display: 'flex', 
        height: 'auto',
        borderBottom: '2px solid',
    },
    burgerContainer: {
        padding: '0px',
        width:'24%',
        borderRight: '2px solid',
    },
    headerContainer: {
        display: 'flex',
        // flexGrow: '1',
        // justifyContent: 'center',
        // alignItems: 'center'
        padding: '8px 0px 0px 106px'
    },
    profileContainer: {
        borderBottom: '2px solid',
    }
}


const OffCanvasSidebar = ({}) => {
    const { currentUser, logOut } = useRealmApp();
    const {user, loading} = useUserByAppUserId(currentUser.id);

    const [show, setShow] = useState(false);

    const handleOpen = () => {
        setShow(!show);
    }

    return (
        <>        
            <div style={styles.container}>
            
                <div style={styles.burgerContainer}>
                <button
                        className="btn align-items-center rounded"
                        onClick={async () => {
                            await logOut();
                        }}
                        >
                        Sign Out
                    </button>
                </div>
                <div style={styles.headerContainer}>
                    Xarc
                </div>
            </div>
            {/* <div style={styles.profileContainer}>
            <SidebarProfileImage currentUser={{...currentUser, "meta": user}} style={styles.profileImg}/>
            </div> */}
            
            {/* <Offcanvas show={show} onHide={handleOpen}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <MenuList currentUser={{...currentUser, "meta": user}} handleLogout={logOut}/>
                </Offcanvas.Body>
            </Offcanvas> */}
        </>
    )
}


export default OffCanvasSidebar;