import { Button, Form, Col, Table } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { title } from '../../helpers/title';
import  dateHelper from '../../helpers/date';
import Topbar from '../../components/topbar/topbar';
import { useRealmApp } from "../../providers/realm/realmApp";
import useJobById from "../../hooks/jobs/useJobById";
import useJobRestrictionsByJobId from "../../hooks/jobs/useJobRestrictionsByJobId";

import { useQuery } from "@apollo/client";
import jobQueries from '../../queries/job-queries';

function JobBasicInfo({ job }) {

    if (job.client_id) {
        return (
            <Form>

                <Form.Group className="mb-3">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder={job.name} disabled />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Job Code: </Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder={job.job_code.toUpperCase()} disabled />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Client:</Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder={job.client_id.name} disabled />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder={job.start_date} disabled />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Project Manager:</Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder={job.manager_id.first_name + " " + job.manager_id.last_name} disabled />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Address:</Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder={job.address + ", " + job.city + ", " + job.state + ", " + job.postcode + ", " + job.country} disabled />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Project Status:</Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder={job.status} disabled />
                </Form.Group>
            </Form>
        );
    } else {
        return (
            <>loading..</>
        )
    }


}

function AssignedForms() {
    return (
        <Form.Group className="mb-3">
            <p>Assigned Forms: </p>
            <Table responsive bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Last Edited</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Worksite Safety Form</td>
                        <td>2021/12/01 07:20</td>
                        <td><Link to={{ pathname: "/form/1234"}}>View</Link></td>
                    </tr>
                </tbody>
            </Table>
        </Form.Group>
    );
}

function DailySignOnRow({ jobSignOnEntries }) {

    // step 2. for each entry, get all related jobSignOnRequriements 

    const [isBusy, setIsBusy] = useState(true)
    const [entries, setEntries] = useState([])
    const { realmApp, currentUser } = useRealmApp();

    useEffect(() => {

        async function calculateStatus() {

            var calculatedJobEntires = []

            for (var entry of jobSignOnEntries) {

                const newStatus = await currentUser.functions.calculateJobSignOnStatus({ "entry": entry });

                calculatedJobEntires.push({ ...entry, ...newStatus },)

            }

            setEntries(calculatedJobEntires)
            setIsBusy(false)
        }

        calculateStatus()

    }, [])

    if (isBusy) return null;

    console.log("entries", entries)

    return entries.map(({ _id, date, total, completed_total }) => (
        <tr key={_id}>
            <td>Sign on</td>
            <td>{dateHelper.timestampToDateString(parseInt(date))}</td>
            <td>{`${completed_total} / ${total}`}</td>
            <td><Link to={{ pathname: "/sign-on/" + _id}}>View</Link></td>
        </tr>
    ));
}

function DailySignOns(props) {

    const job = props.job;

    const { data, loading, error } = useQuery(
        jobQueries.getJobSignOnEntriesForJobId,
        {
            variables: {
                job: {
                    "_id": job.id
                },
            },
        }
    );

    if (loading) {
        return (<>loading...</>)
    }

    // step 1. get job sign on entries
    const jobSignOnEntries = data.jobSignOnEntries

    if (jobSignOnEntries) {
        return (
            <Form.Group className="mb-3">
                <p>Daily Sign Ons: </p>
                <Table responsive bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Completed Sign Ons</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        <DailySignOnRow jobSignOnEntries={jobSignOnEntries} />
                    </tbody>
                </Table>
            </Form.Group>
        );
    } else {
        return (
            <></>
        );
    }


}

function ActiveSiteRestrictions(props) {

    var jobId = props.jobId;

    const { jobRestrictions, loading } = useJobRestrictionsByJobId(jobId);

    return (
        <Form.Group className="mb-3">
            <p>Active Site Restricitons: </p>
            <Table responsive bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        jobRestrictions.map(({ _id, name }) => {
                            return (
                                <tr key={_id}>
                                    <td>{name}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </Form.Group>
    );
}

function SiteRequiredQualifications() {
    return (
        <Form.Group className="mb-3">
            <p>Site Required Qualifications: </p>
            <Table responsive bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Forklift Licence</td>
                    </tr>
                    <tr>
                        <td>Working at heights ticket</td>
                    </tr>
                </tbody>
            </Table>
        </Form.Group>
    );
}

function AssignedWorkers() {
    return (
        <Form.Group className="mb-3">
            <p>Assigned Workers: </p>
            <Table responsive bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Last Signed On</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Joe Blogs</td>
                        <td>2021/12/01 07:20</td>
                        <td><Link to={{ pathname: "/user/1234"}}>View</Link></td>
                    </tr>
                </tbody>
            </Table>
        </Form.Group>
    );
}

function Job(props) {

    var path = window.location.pathname;

    var jobId = path.replace('/job/', '');

    document.title = title('Job | ' + jobId);

    const { job, loading } = useJobById(jobId);

    const submitButton = useRef(null);

    const [disabled, setDisabled] = useState(true);

    const handleEnable = () => setDisabled(false);
    const handleDisable = () => setDisabled(true);

    return (
        <div>
            <Topbar title={"Job"} />
            <div className='page-container'>
                <Col sm={6}>

                    <JobBasicInfo job={job} />

                    <DailySignOns job={job} />

                    <ActiveSiteRestrictions jobId={job._id} />

                    <SiteRequiredQualifications />

                    <AssignedWorkers />

                    <Link to={{ pathname: "/job/edit/" + job._id}} className="btn btn-primary">
                        Edit
                    </Link>
                </Col>

            </div>
        </div >
    );
}



export default Job;