import React, {useState, useEffect, useCallback} from 'react'
import { Button, Table } from 'react-bootstrap';
import { dummyAssets } from '../dummies/dummyAssets';
import ServiceEditModal from '../modal/ServiceEditModal';

const styles = {
    upperCase: {
        textTransform:'capitalize'
    }
}
const findServiceRecord = async (id) => await dummyAssets.find(asset => asset.id === id).serviceSchedule;

const ServiceTable = ({ services, assetId, onInputChange, ...props }) => {

    const defaultModalState = {
        mode: 'Add',
        data: {}
    };
    const [serviceRecord, setServiceRecord] = useState([]);
    const [editTable, setEditTable] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalState, setModalState] = useState(defaultModalState);
    
    useEffect(() => {

        if (services && services.length !== 0) {
            //console.log('1')
            setServiceRecord(services);
            
        }
        else {
            //console.log('2')
            findServiceRecord(assetId).then(data => {
                console.log('record', data);
                setServiceRecord(data);
            }
                    
            );
            
        }

    }, [services, assetId]);

    const handleEditTable = (e) => {
        if (editTable) {
            console.log('Record saving to db')
        }
        
        setEditTable(!editTable);
    }

    const handleRowEdit = useCallback((e, idx) => {
        const rowData = serviceRecord[idx];
        setModalState({
            mode: 'Edit',
            index: idx,
            data: rowData,
        })

        setModalOpen(true);
    }, [serviceRecord]);
    
    const handleAddRecord = () => {
        const modalState = {
            mode: 'Add',
            data: {}
        }
        setModalState(modalState);
        setModalOpen(true);
    }

    const handleModalSave = (modalData) => {
        if (modalState.mode === 'Add') {
            setServiceRecord([...serviceRecord, modalData]);
        }
        if (modalState.mode === 'Edit') {
            const index = modalState.index;
            const newData = modalData;

            if (serviceRecord.length !== 0) {
                const newRecord = serviceRecord;
                newRecord[index] = newData;

                setServiceRecord(newRecord);
            }

        }
        setModalOpen(false)
    }

    return (
        <div style={{padding:'20px 0'}}>
            <div style={{paddingBottom:'30px'}}>
                <h3>ServiceTable</h3>
            </div>
            <div>
                <Table responsive bordered hover size="sm">
                    <thead>
                        <tr>
                            { serviceRecord && serviceRecord.length!==0 && Object.keys(serviceRecord[0]).map(key => {
                                    return (<th key={key} style={styles.upperCase}>{key}</th>)
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {serviceRecord && serviceRecord.length!==0 && serviceRecord.map((service, idx) => {
                            return (
                                <tr key={idx} >
                                    <td name={`name-${idx}`}>{service.name}</td>
                                    <td name={`description-${idx}`}>{service.description}</td>
                                    <td name={`dateDue-${idx}`}>{service.dateDue}</td>
                                    <td name={`avgKm-${idx}`}>{service.avgKm}</td>
                                    {editTable ? (<td><Button onClick={(e) => handleRowEdit(e, idx)} variant='secondary'> Edit</Button></td>): null}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <div style={{display:'flex', justifyContent:'right'}}>
                    {editTable ? (<Button variant='primary' style={{float: 'right'}} onClick={handleAddRecord}> Add </Button>) : null}
                </div>
                 
                <Button variant='primary' onClick={handleEditTable} >{ editTable ? 'Save' : 'Edit' }</Button>
            </div>
            
            <ServiceEditModal show={modalOpen} onHide={() => setModalOpen(false)} modalState={modalState} onModalSave={handleModalSave} />

        </div>
    );
}


export default ServiceTable;