import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const GetUserByIdQuery = gql`
  query GetUserById($_id: ObjectId!) {
      users(query: { _id: $_id }) {
        _id
      app_user_id
      email
      first_name
      last_name
      phone_number
      type
      profile_picture
      }
  }
`;

export function useGetUserById(id) {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    GetUserByIdQuery,
    {
      variables: {
        _id: id,
      },
    }
  );

  const user = data?.users.length > 0 ? data?.users[0] : {};
  return {
    user,
    loading,
  };
}